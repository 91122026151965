.custom-dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.custom-dropdown-header {
  background: #1f1f1f;
  color: white;
  font-size: 20px;
  border-radius: 25px;
  border: 3px solid #3fd999;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto;
}

.custom-dropdown-items {
  background: #1f1f1f;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: fit-content;
  border: 3px solid #3fd999;
  width: 150px;
  min-width: 150px;
  cursor: pointer;
}

.custom-dropdown-items.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  z-index: 111;
}

.custom-dropdown-items .custom-dropdown-item {
  background: #1f1f1f;
  color: white;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  width: 100%;
}
