.profile-container {
  padding: 0 20px;
  font-size: 14px;
}

.user-details {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  color: white;
}

.profile-picture-container {
  width: 40%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.profile-picture {
  background: #c4c4c4;
  border: solid 1px #3fd999;
  width: -webkit-fill-available;
  border-radius: 99px;
  width: 6.6em;
  height: 6.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-info {
  width: 60%;
  display: flex;
  align-items: center;
}

.profile-details {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.profile-season-container {
  width: 40%;
  color: white;
  text-align: center;
}

.profile-actions {
  margin-bottom: 10px;
}

.profile-seasons {
  border: solid 1px #c4c4c4;
  border-radius: 10px;
  padding: 5px;
  color: white;
  text-align: center;
}

.profile-picture {
  padding: 5px;
}

.profile-cards-container {
  width: 60%;
  color: white;
}

.profile-cards {
  border: solid 1px #c4c4c4;
  border-radius: 10px;
  padding: 5px;
}

.profile-cards .card {
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.profile-cards .card .card-desc {
  font-size: 10px;
}

.profile-cards .card .card-desc .last-check-off {
  color: rgb(189, 189, 189);
  font-style: italic;
}

.seasons-list {
  max-height: 300px;
  font-size: 12px;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 0 !important;
}

.cards-list {
  max-height: 325px;
  font-size: 12px;
  overflow-y: scroll;
}

.card-image {
  width: 50%;
  height: fit-content;
  border: solid 2px #3fd999;
  border-radius: 10px;
  position: relative;
}

.seasons-list ul {
  list-style: none;
  padding: 0;
}

.seasons-list li:first-child {
  margin-top: 15px;
}

.seasons-list li {
  border: solid 1px #c4c4c4;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.seasons-list li:before {
  display: none !important;
  content: "last";
  position: absolute;
  top: -15px;
  left: 0px;
  border: solid 1px #3fd999;
  border-radius: 70px;
  padding: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
}

.profile-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #c4c4c4;
  margin-bottom: 5px;
}

.profile-card-actions span {
  padding-bottom: 5px;
}

.profile-card-actions span.active {
  border-bottom: solid 1px #3fd999;
  color: #3fd999;
}

@media only screen and (min-width: 600px) {
  .profile-cards .cards-list .card img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .profile-cards .card .card-desc {
    font-size: 16px;
    margin-left: 20px;
  }

  .profile-details {
    gap: 20px;
  }

  .seasons-list {
    font-size: 16px;
  }

  .profile-seasons {
    padding: 20px;
  }

  .profile-cards {
    padding: 20px;
  }

  .profile-card-actions span {
    width: 33%;
    text-align: center;
  }

  .seasons-list li:before {
    font-size: 12px;
  }

  .card-image:before {
    font-size: 12px;
  }

  .profile-picture {
    min-width: 200px;
    min-height: 200px;
  }
}

.cta-add::before {
  content: "+";
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border: solid 1px #3fd999;
  border-radius: 50px;
  position: absolute;
  left: 0;
  top: 0;
}

.cta-add {
  position: relative;
  padding-left: 30px;
  margin: auto;
  width: fit-content;
}

.card-image {
  position: relative;
}

.hit-info .season-name a{
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.hit-info{
  display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-profile .last-check-off{
  margin-top: 10px;
}

.hit-info .player-name{
  text-align: left;
}

.hit-info .player-name a{
  color: white;
  text-align: left;
}

.hit-info .card-name{
  margin: 0;
}

.hit-info .season-name{
  font-size: inherit;
}

.profile-card-actions{
  display: none;
}

.profile-seasons {
  height: 91% !important;
}

@media only screen and (max-width: 600px) {
  #imagePreview{
    width: auto;
    aspect-ratio: 1;
  }

  .avatar-upload{
    max-width: 100%;
  }

  #imagePreview{
    width: 100%;
    height: auto;
  }

  .user-info{
    padding: 20px 0;
  }

  .user-profile-pic{
    overflow: hidden;
    width: 40%;
  }

  .top-account-info{
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
  }

  .top-account-info .name{
    font-size: 16px;
  }

  .top-account-info .join-date{
    font-size: 14px;
  }

  .top-account-info .edit-profile-btn{
    padding: 5px 10px;
    font-size: 16px;
    letter-spacing: 0;
    margin-top: 10px;
    text-transform: inherit;
    text-align: left;
  }

  .top-account-info .bio-head{
    font-size: 16px;
    line-height: 1.2em;
    margin-top: 5px;
  }

  .top-account-info .bio-content{
    font-weight: 400;
    font-size: 14px;
  }

  .profile-details .border-box{
    margin: 0;
    padding: 10px;
  }

  .profile-details .profile-overview{
    width: 100%;
  }

  .profile-details .profile-season-container{
    width: 100%;
  }

  .profile-details .seasons-list {
    max-height: 360px;
  }

  .profile-head{
    font-size: 16px;  }

  .hits-count{
    font-size: 14px;
    margin: 5px 0;
  }

  .hit-info{
    padding: 0;
    margin: 0;
  }

  .hit-info .season-name a{
    font-size: 14px;
    
  }

  .hit-info .season-name{
    line-height: 1.2em;
  }

  .card-name{
    font-size: 14px;
    line-height: 1.2em;
    margin-top: 5px !important;
  }

  .checklist-of-container{
    max-height: 340px;
  }

  .avatar-upload .avatar-preview{
    width: auto;
    height: auto;
  }

  .user-profile .profile-details{
    flex-direction: column;
  }

}

.user-profile, .hit-container:first-child{
  margin-top: 0;
}

.profile-actions{
  display: none;
}

.profile-seasons{
  height: 100% !important;
}

.profile-overview{
  margin-bottom: 0 !important;
}

#editprofile .user-info-popup{
  padding: 20px;
}

.no-record{
  border: none;
}

.profile-seasons{
  position: relative;
}


.seasons-list{
  max-height: 380px ;
}

.seasons-list ul li a{
  color: white;
}

.profile-seasons .seasons-list li.no-record:before{
  display: none;
}

.profile-seasons .seasons-list li.no-record{
  position: absolute;
  width: auto;
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-profile.not-logged-in{
  position: relative;
}

.user-profile.not-logged-in .login-in-overlay-cta{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.303);
  z-index: 1;
  backdrop-filter: blur(10px);
}



.user-profile.not-logged-in .login-in-overlay-cta{
  display: grid;
  place-items: center;
}

.user-profile.not-logged-in .login-in-overlay-cta .login-desc{
  font-size: 16px;
}

.user-profile.not-logged-in{
height: 85vh;
    overflow: hidden;}