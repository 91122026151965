.leaders-container {
  padding: 0 20px;
}
.leaders-container .season {
  text-align: center;
  margin: 0;
}

.leaderboard {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.leaders-list {
  border: solid 1px #c4c4c4;
  padding: 10px;
  border-radius: 10px;
  width: 70%;
  color: white;
  font-size: 16px;
}

.leader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.leader .leader-name {
  border: solid 1px #3fd999;
  padding: 2px 10px 2px 5%;
  border-radius: 99px;
  position: relative;
}

.leader-info .followers  ul li:hover:before{
  width: auto;
}

.leader .leader-name::before {
  content: "1";
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border: solid 1px #3fd999;
  border-radius: 50px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.leader.verified .leader-name::after {
  content: "";
  background: url(../../img/Verified\ Logo.png);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  background-size: cover;
  border-radius: 50px;
  position: absolute;
  right: -30px;
  top: 0;
}

.current-leader {
  border: solid 1px #c4c4c4;
  padding: 10px;
  border-radius: 10px;
  width: 40%;
  color: white;
  font-size: 16px;
  text-align: center;
}

.current-leader .heading {
  color: #3fd999;
  text-decoration: underline;
}

.leader-image {
  width: -webkit-fill-available;
  margin-top: 12px;
  max-width: 200px;
}

.current-leader {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leaders-list {
  max-height: 400px;
  overflow-y: scroll;
}

.current-leader .heading {
  font-weight: bold;
}

.leader.active {
  background: #3fd999;
  border-radius: 20px;
  color: black;
  font-weight: 600;
}

.leader {
  padding-right: 10px;
}

/* #leader-popup{
  display: flex !important;
} */

.leader-popup{
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  width: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  max-width: 1000px;
  background: rgba(0,0,0,.278);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  max-height: 90vh;
  border-radius: 10px;
  border: 1px solid #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.leader-image{
  width: 20em;
    height: auto;
    border-radius: 50em;
    border: solid 3px #3fd999;
    aspect-ratio: 1;
    object-fit: cover;
    cursor: pointer;
}

.leader-name, .leader-popup .points{
  margin-top: 20px;
  color: white;
  font-weight: bold;
  font-size: 22px;
}

.leader-popup .points{
  font-size: 18px;
}

.followers{
    font-size: 12px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0!important;
}

.followers ul{
  list-style: none;
    padding: 0;
    display: grid;
    grid-template: "1fr 1fr";
}

.followers ul li{
  font-size: 13px;
    margin: 5px;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 60px;
    position: relative;
    line-height: 1.1;
    color: white;
}

.follow-button{
  transition: 400ms;
}

.followers ul li:before{
  content: "+";
  position: absolute;
  top: -5px;
  right: -5px;
  background: black;
  border: solid 1px #3fd999;
  height: 22px;
  width: 22px;
  border-radius: 10em;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.followers ul li:hover:before{
  content: "Follow";
  width: fit-content;
  padding:  2px 5px;
}

.checklist-instructions, .mutuals{
  text-align: left;
}

.followers ul li.followed:before{
  content: "✓";
  background: #3fd999;
  color: black;
  font-weight: bold;
}

.followers ul li.followed:hover:before{
  content: "Unfollow";
}

.leader-info{
  display: flex;
  width: 100%;
}

.leader-info .leader-name{
  cursor: pointer;
}

.followers{
  width: 50%;
}

.checkoff-cards{
  width: 50%;
}

.checkoff-cards img{
  border-radius: 20px;
  width: 150px;
}

.card{
  display: flex;
}

.card-desc{
  color: white;
}

/* #check-off.hit-btn{
  display: none;
} */

.indexOfLeader{
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1;
  border: solid 1px #3fd999;
  border-radius: 50px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.leader-name{
  margin: 0;
}

.leader.active .leader-name{
  color: black !important;
}

.leader-details{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.leaders-container .search-season{
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .leaderboard{
    flex-direction: column;
  }

  .leader-info .leader-image{
    width: 50%;
  }

  .leader-details {
    margin-left: 5px;
  }

  .leaderboard>div{
    width: 100%;
  }

  .followers ul{
    padding: 2px;
  }


  .leader-name{
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }

  .indexOfLeader{
    height: 100%;
    width: auto;
    aspect-ratio: 1;
  }

  .score{
    font-size: 12PX;
  }

  .leader-popup{
    padding: 10px;
  }

  .followers{
    width: 60%;
  }

  .checkoff-cards{
    width: 40%;
  }

  .followers ul li{
    font-size: 10px;
    margin: 2px;
  }
  
  .checkoff-cards img{
    width: auto;
  }

  .leader-info .card{
    flex-direction: column !important;
  }
}


.leader-info .card::before{
  display: none;
}

.leader-info .card{
  flex-direction: row;
}

.mutuals{
  color: white;
  font-size: 16px;
}

.checklist-instructions{
  color: white;
  font-style: italic;
}

.text-green{
  color: #3fd999;
}

.info-profile{
  width: 100%;
}

.leader-popup{
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .leader-image{
    width: 14em;
  }

  .leader-popup{
    width: 80%;
  }
}

.leaderboard{
  position: relative;
  overflow: hidden;
}

.leaders-list, .current-leader  {
  position: relative;
}

.leaderboard .login-overlay, .leaderboard .leader-login-overlay{
  display: none;
}

.leaderboard.Empty .login-overlay, .leaderboard .leader-login-overlay{
position: absolute;
    backdrop-filter: blur(5px);
    background: #000000b0;
    width: inherit;
    top: 0;
    left: 0;
    height: -webkit-fill-available;
    display: flex;
    place-content: center;
    align-items: center;
    z-index: 10;
    gap: 3px;
    width: -webkit-fill-available;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    
    /* border: solid 1px white;
    padding: 10px;
    border-radius: 10px; */
  }

  .leaderboard.Empty .login-overlay{
  display: flex;
    flex-direction: row;}

  /* .leaderboard.Empty .current-leader{
    border: none;
    padding: 0;
  } */

  .leaderboard.Empty{
    height: auto !important;
  }


  .leaders-list{
    overflow: hidden;
  }

  .leaderboard.Empty .login-overlay .text {
    font-size: 16px;
    color: white  ;
  }

  .circle-text{
    width: min-content;
    background: black;
    border: solid 3px #3fd999;
    display: grid;
    place-content: center;
    border-radius: 9999px;
    aspect-ratio: 1;
    padding: 10px;
    font-size: 16px;
  }

  a.text-orange{
    color: #FDA130;
  }

  .followers li{
    cursor: pointer;
  }

/* .leaderboard.Empty .leaders-list:before{
  content: "Be first to check off cards for this checklist and claim Leader status!";
  background-color: rgba(0, 0, 0, 0.305);
  position: absolute;
  top: 0;
  left: 0;
  width: 65%;
  height: 100%;
  z-index: 10;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
} */

.followers ul li:before{
  display: none;
}

.leader-popup .followers ul li span:first-child{
    position: absolute;
    top: -5px;
    right: -5px;
    background: black;
    border: solid 1px #3fd999;
    height: 22px;
    width: 22px;
    border-radius: 10em;
    display: grid;
    place-items: center;
    cursor: pointer;
    /* transition: all 400ms ease-in-out; */
    padding: 0 !important;}

.leaders-list .leader{
  cursor: pointer;
}

.leader-popup .followers ul li span:first-child::before{
  content: "Follow";
  font-size: 12px;
  display: none;
  opacity: 0;
}

.leader-popup .followers ul li span.followed{
  background: #3fd999;
  color: black;
  font-weight: 600;
}

.leader-popup .followers ul li span.followed:first-child::before{
  content: "Unfollow";
  font-weight: 600;
}

.leader-popup .followers ul li span:first-child:hover{
  font-size: 0;
  padding: 2px 5px !important;
  width: fit-content;
  border-radius: 40px !important;
}

.leader-popup .followers ul li span:first-child:hover::before{
  opacity: 1;
  display: block;
}

@media only screen and (min-width: 600px){
.leaders-container .season{
    width:40%;
    }

    .leaders-season-container{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: 600px){
    .login-overlay{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  
 .search-season input{
    font-size: 20px !important;
    line-height: 1.2;
    margin-bottom: 0.5rem !important;
    font-weight: 500;
    background: none !important;
    padding: 20px !important;
    border: solid 1px #3fd999 !important; 
    border-radius: 10px !important;
    margin: 0 !important;
    height: auto !important;
    text-align: center;
    font-family: inherit !important;
  }

  .leaders-container .search-season{
    margin-bottom: 0 !important;
    /* padding: 10px; */
  }

  .leader-popup .card{
    border: none ;
  }

  .leader-popup .mutuals{
    margin-left: 10px;
  }

  .leader-info .no-record{
    border: none;
    font-size: 14px;
  }

  span.follow-checklist{
    color: #3fd999;
    cursor: pointer;
  }

  span.follow-checklist:hover{
    text-decoration: underline;
  }