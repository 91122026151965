.sponsor-banner-container {
}
.sponsor-img-wrapper {
  height: 100%;
  width: 100%;
}
#sponsor-link {
  height: 100%;
  width: 100%;
}
.sponsor-img {
  height: auto;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

@media (max-width: 800px) {
  .sponsor-img {
    width: 100%;
    margin: 0;
  }
}
