.add-remove-btn {
  margin-top: 20px !important;
}

.add-remove-btn:hover {
  cursor: pointer !important;
}
.clear-all-btn:hover {
  cursor: pointer !important;
}
.add-remove-wrapper {
  display: flex;
}
.new-icon-wrapper {
  margin-left: 10px;
}
.checkbox-container {
  /* border: 1px solid red !important;
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 20%;
  padding: 8px;
  margin-left: 75%;

  color: white;
  margin-top: 50px;
  margin-right: 20px; */
  z-index: 99;

  position: absolute;
  display: none;
  flex-direction: column;
  /* width: 15%;
  margin-left: 85%; */
}
#checkbox {
  border: none;
  color: white;
  /* max-width: 100%;
  width: 100%; */
  margin-bottom: 6px;
  height: 40px;
  box-sizing: border-box;
  font-size: 25px;
  border: 1px solid white;
  text-align: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 5px;
  margin-right: 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.randomizer-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.randomizer-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .randomizer-slider {
  background-color: #3fd999;
}

input:focus + .randomizer-slider {
  box-shadow: 0 0 1px #3fd999;
}

input:checked + .randomizer-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.payment-p {
  text-decoration: none !important;
  border: none !important;
  font-size: 1rem !important;
}
/* Rounded sliders */
.randomizer-slider.round {
  border-radius: 34px;
}

.randomizer-slider.round:before {
  border-radius: 50%;
}
.spot-column-toggle {
  display: flex;
  margin-top: 20px;
}
