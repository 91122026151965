.sign-up-page .sign-up-page-header {
  color: white;
  font-size: 50px;
  text-align: center;
}

@media (max-width: 1052px) {
  .sign-up-page .sign-up-page-header {
    font-size: 25px;
  }
}

.sign-up-page-sub-header{
  font-size: 20px;
  color: white;
}