.signup-confirm-wrapper {
  width: 70%;
  margin: auto;
}

.signup-confirm-content {
  width: 100%;
  color: white;
  font-size: 25px;
}

.signup-confirm-content-top {
  width: 100%;
  color: white;
  font-size: 25px;
  margin-bottom: 70px;
}

.signup-confirm-content-logo {
  width: fit-content;
}

.signup-confirm-content-logo h3 {
  font-size: 50px;
}

@media (max-width: 1052px) {
  .signup-confirm-wrapper {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .signup-confirm-wrapper {
    width: 90%;
    font-size: 25px;
  }
}

.follow-us-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  line-height: 19px;
  color: #e5e5e5;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 0;
  width: 100%;
  margin-right: -5% !important;
  margin-top: 10px;
}
