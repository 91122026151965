.faq-section {
  width: 70%;
  margin: auto;
}

.faq-section-header {
  font-size: 50px;
}

@media (max-width: 1052px) {
  .faq-section {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .faq-section {
    width: 90%;
    font-size: 25px;
    margin: 20px auto !important;
  }

  .accordion-header button{
    font-size: 18px !important;
  }
}
