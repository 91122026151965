.footer a {
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.footer svg {
  fill: white;
}
.footer {
  margin-top: 200px;
}
.mx-3:hover {
  color: #04aa6d;
}
