.privacy-section {
  width: 70%;
  margin: auto;
  font-family: "Work Sans", sans-serif;
}

.privacy-section-header {
  font-size: 50px;
}

.accordion-button {
  font-size: 25px !important;
}

.accordion-body p {
  font-size: 20px;
}

@media (max-width: 1052px) {
  .privacy-section {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .privacy-section {
    width: 90%;
    font-size: 25px;
  }
}
