.blog-header {
  font-size: 40px;
  color: #fff;
  font-family: "Work Sans", sans-serif;

  margin-bottom: 5rem;
  text-align: center;
  margin-top: 50px;
}

#trending-hits {
  font-size: 3rem;
}

.TDContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;

  width: 100%;
  padding: 1%;
  text-align: center;
}

#hits-title {
  margin: 20px;
}

.blog-p {
  font-size: 2rem;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
}

*/
/* New Css */

.text-align-center {
  text-align: center;
}

#upload {
  /* border: 1px solid red; */
  font-size: 1.5rem;
}

#check-off-popup {
  font-size: 2.5rem;
}

#bang {
  font-size: 1.5rem;
}

#pending {
  font-size: 1.5rem;
}

.filter-container {
  display: flex;
  justify-content: center;
}

.filter-container .filter {
  width: 33%;
  margin: 0 15px;
  background: transparent;
  border-radius: 0;
  border: solid 1px #04aa6d;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  min-width: 200px;
}

.card-container {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.data {
  border: 1px solid #272929;
  border-radius: 10px;
  background: #272929;
  display: flex;
  margin-top: -10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  padding-bottom: 40px;
}

.hit-card {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.hit-img-container {
  background: rgba(255, 255, 255, 0.01);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
  width: 100%;
  /* height: 200px; */
  object-fit: contain;
  transition: 200ms;
  margin: 0;
  margin-top: 20px;
  /* box-shadow: 0 2px 4px #04aa6d; */
}

#hit-img-container:hover {
  border: 1px solid #04aa6d;
}

#hit-card-image:hover {
  transform: scale(1.1);
}

.hit-card:hover .hit-img-container {
  border-color: #04aa6d;
}

.hit-card:hover .hit-img-container .hit-card-image {
  transform: scale(1.1);
}

.hit-card:hover .card-title {
  color: #04aa6d;
  text-decoration: none;
}

.card-container a:hover {
  text-decoration: none;
}

.checklists-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border-bottom: 2px solid black;
}

.header-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
  margin: 30px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.hit-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.latest-hits-header {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-transform: uppercase;
  color: white;
  font-weight: bolder;
  font-size: 3rem;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.top-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.latest-hits {
  width: 100%;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-bottom: 10px;
}

.latest-hits:hover {
  color: #04aa6d;
}

#hit-btn {
  white-space: nowrap;
  text-transform: uppercase;
  color: black !important;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 3px;
  margin: 5px;
}

#check-off {
  white-space: nowrap;
  text-transform: uppercase;
  color: black !important;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 3px;
  margin-top: 20px;
}

.hit-card-image {
  height: 200px;
  object-fit: contain;
  transition: 200ms;
  margin: auto;
  display: flex;
  justify-content: center;
}

#hit-card-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  transition: 200ms;
}

#hit-img-container {
  background: rgba(255, 255, 255, 0.01);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 2px 4px #04aa6d; */

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  margin: 30px;
  display: flex;
}

#checklists-h {
  width: 100%;
  /* margin-left: 10%; */
  /* margin-right: 10%; */
  text-transform: uppercase;
  color: white;
  font-weight: bolder;
  font-size: 3rem;
  letter-spacing: 2px;
  margin-bottom: 0;
  white-space: nowrap;
  text-align: center;
}

.left-right {
  /* display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 50px;
  margin-bottom: 20px; */
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.left {
  /* border: 1px solid yellow; */
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  text-align: left;
}

.right {
  width: 55%;
  height: 100%;
}

.right-img {
  height: auto;
  width: 100%;
}

.hits-uploads-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#select-card-img {
  background: rgba(255, 255, 255, 0.01);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 2px 4px #04aa6d; */

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.card-title {
  font-size: 2.5rem;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin: 5%;
  /* text-align: left; */
}

.back-to-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.back-to-link {
  color: white;
  text-decoration: underline;
  font-size: 2rem;
  font-family: "Work Sans", sans-serif;
}

.back-to-link:hover {
  color: #04aa6d;
}

.hit-cards-page {
  margin: 0 5%;
}

.top-html-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .uploads-btn-season {
    margin: 0 !important;
    margin-top: 20px !important;
  }

  .filter-container {
    flex-direction: column;
    align-items: center;
    margin: 15px;
  }

  #trending-hits {
    text-align: center;
  }

  #select-card-img {
    width: 90%;
    margin: auto;
  }

  .filter-container .filter {
    width: 100%;
    margin: 5px;
  }

  .hit-card {
    width: 100%;
  }

  .uploaded-card {
    width: 100% !important;
    margin-top: 20px;
  }

  .player-popup {
    width: 80% !important;
  }

  .player-accordins {
    grid-template-columns: repeat(1, 1fr) !important;
    padding: 10px;
    grid-gap: 0 !important;
  }

  .inner-card-container {
    display: flex;
    padding: 5px;
  }

  .back-to-link {
    padding: 10px;
  }
}

.inner-card-data {
  margin: 15px;
  display: flex;
  align-items: flex-start;
}

.inner-hit-img-container {
  background: rgba(255, 255, 255, 0.01);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* border: 1px solid #3fd999; */

  padding: 15px;
  /* flex: 1; */
  width: 33%;
  margin: 30px;
  box-shadow: 0 2px 4px #04aa6d;
}

.inner-card-desc {
  margin-top: 0 !important;
}

.player-accordins {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  grid-gap: 10px;
}

.accordin-column {
  width: 33%;
}

/* Accordin */

.ac-label,
.ac-label:focus {
  font-weight: 700;
  position: relative;
  padding: 15px;
  padding-left: 10px;
  padding-right: 45px;
  /* margin-left: 5px;
  margin-right: -5px; */
  margin-bottom: 0;
  display: block;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out;
  color: white;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px !important;
}

/* .ac-input:checked+label,
.ac-label:hover {
  background-color: #999;
} */

.ac-label:after,
.ac-input:checked + .ac-label:after {
  content: "+";
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 2em;
  height: 100%;
  line-height: 2.25em;
  text-align: center;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out;
  font-size: 24px;
}

.uploaded-container {
  padding: 15px;
  background: rgba(255, 255, 255, 0.01);
  border-radius: 5px;
  box-shadow: 0 2px 10px #04aa6d;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 30%;
}

#cards {
  width: 100% !important;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

/* .ac-label:hover:after,
.ac-input:checked+.ac-label:after {
  background-color: #b5b5b5;
} */

.ac-input:checked + .ac-label:after {
  content: "-";
}

.ac-input {
  display: none;
}

.ac-text,
.ac-sub-text {
  display: none;
  opacity: 0;
  height: 0;
  margin-bottom: 0.5em;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
}

.ac-input:checked ~ .ac-text,
.ac-sub .ac-input:checked ~ .ac-sub-text {
  display: flex;
  opacity: 1;
  height: auto;
}

.ac-sub .ac-label:checked {
  background: none;
  border-bottom: 1px solid whitesmoke;
}

.ac-sub .ac-label:after,
.ac-sub .ac-input:checked + .ac-label:after {
  right: 0;
  background: none;
  /* border: 1px solid red !important; */
}

.ac-sub {
  width: 100%;
  border: solid 1px #04aa6d;
  margin-bottom: 1.5em;
  border-radius: 5px;
}

.ac-sub .ac-input:checked + label,
.ac-sub .ac-label:hover {
  background: none;
}

.ac-sub-text {
  padding: 0 1em 0 2em;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.card-input-check {
  display: flex;
  margin: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 7em;
}

.card-check,
.card-check-label {
  margin: 0 !important;
  font-size: 16px;
  margin-right: 5px !important;
}

#last-update {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

@media only screen and (max-width: 800px) {
  .top-wrapper {
    display: flex;
    flex-direction: column;
  }

  .data {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .latest-hits-header {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .header-btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .latest-hits {
    width: 100%;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: normal;
    margin-top: 30px;
    text-align: center;
    margin: 0;
  }

  #hit-btn {
    margin-top: 20px;
  }

  .inner-card-data {
    /* flex-direction: column; */
    /* align-items: center; */
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .submitted-notes {
    color: white;
    margin-top: 20px !important;
    font-size: 1.8rem !important;
    overflow-y: scroll !important;
  }

  .inner-hit-img-container {
    width: 100%;
  }

  .accordin-column {
    width: 100%;
  }
}

.player-popup {
  /* background-color: rgb(50, 50, 50);
  width: -moz-fit-content;
  width: fit-content;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  max-height: 90vh;
  border-radius: 5px; */

  position: fixed;
    left: 50%;
    top: 50%;
    z-index: 999999;
    width: 50%;
    transform: translate(-50%, -50%);
    max-width: 1000px;
    background: #121212;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: scroll;
    max-height: 90vh;
    border-radius: 10px;
    border: 1px solid #FDA130;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.player-popup h2 {
  color: white !important;
  font-weight: bold;
  width: 80%;
  font-size: 2rem;
}

.upload-icon {
  width: 50px;
  cursor: pointer;
  margin-top: 20px;
}

.button-container {
  margin-top: 20px;
}

.button-container button {
  margin: 0 10px;
  padding: 5px 15px;
  transition: 300ms;
}

.button-container button:hover {
  transform: scale(1.1);
}

.player-popup .submit-button,
.primary-button {
  background: #04aa6d;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
}

.player-popup .reset-button {
  border: solid 1px #04aa6d;
  color: #04aa6d;
  font-size: 16px;
  background: transparent;
  border-radius: 5px;
}

.uploaded-card {
  width: 200px;
  margin-top: 20px;
  object-fit: contain;
}

.player-popup {
  position: fixed;
  left: 50%;
  /* margin-left: -150px; */
  top: 50%;
  /* margin-top: -150px; */
  z-index: 20;
  width: fit-content;
  transform: translate(-50%, -50%);
  max-width: 1000px;
}

.player-popup .submitted-notes {
  word-break: break-all;
  overflow-y: scroll;
}

.player-popup {
  -webkit-box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
}

/* .player-popup:after{
  position: fixed;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: -2;
} */

.close-button {
  background: none;
  border: none;
  color: white;
  transition: 300ms;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: "poppins";
}

.close-button:hover {
  color: #04aa6d;
}

.card-notes {
  width: -webkit-fill-available;
  background: transparent;
  margin-top: 20px;
  border: solid 1px #04aa6d;
  color: white;
}

.submitted-notes {
  color: white;
  margin-top: 20px;
  font-size: 1.8rem;
  overflow-y: scroll !important;
}

.top-hits-card-details {
  color: white;
  font-size: 16px;
  margin-top: 10px;
  font-size: 2rem;
  /* word-break: break-all;
  white-space: nowrap; */
  width: 100%;
  /* padding: 20px; */
  word-break: break-word;
}

.mt-2 {
  margin-top: 2em !important;
}

.mt-4 {
  margin-top: 4em !important;
}

.card-upload-area {
  display: flex;
  justify-content: space-evenly;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
}

.card-upload-area div {
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-upload-area p {
  color: white;
  text-align: center;
  margin-top: 10px;
}

.popup-hit-image-container img {
  margin-right: 10px;
}

@media only screen and (max-width: 800px) {
  .hit-cards-page {
    margin: 0 0%;
  }

  #hit-img-container {
    width: 70%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* #cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */
  .uploaded-container {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; */
    box-shadow: 0 2px 4px #04aa6d;
  }

  .inner-hit-img-container {
    width: 70%;
    margin: 10px;
  }

  .top-html-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 0;
  }

  .hit-btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .popup-hit-image-container img {
    width: 50% !important;
  }

  .popup-hit-image-container {
    display: flex;
  }

  .player-popup {
    padding: 20px !important;
  }

  .inner-top-cta-container {
    flex-direction: column;
  }
}

select.filter option {
  color: black;
}

.ac {
  margin: 0 1%;
}

.primary-button-green {
  background: #04aa6d;
  color: white;
  border: none;
  font-size: 16px;
  transition: 300ms;
  padding: 10px 15px;
  min-width: 130px;
  text-align: center;
}

.primary-button-green:hover {
  transform: scale(1.1);
  text-decoration: none !important;
  color: white !important;
}

.inner-top-cta-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-upload-area div {
  color: white;
}

.login-popup-button {
  margin-top: 10px;
}

/* .uploaded-container {
  box-shadow: 0 2px 4px #04aa6d;
} */

.login-popup-button a {
  background: #04aa6d;
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: 200ms;
}

.login-popup-button a:hover {
  transform: scale(1.2);
}

.hit-btn {
  padding: 20px 50px;
  font-size: 20px;
  background: #3fd999;
  border: none;
  cursor: pointer;
  transition: 200ms;
  border-radius: 5px;
}

.hit-btn:hover {
  background: #04aa6d;
}

.uploads-btn {
  padding: 20px 50px;
  font-size: 20px;
  background: none;
  border: 1px solid #3fd999 !important;
  border: none;
  cursor: pointer;
  transition: 200ms;
  border-radius: 5px;
  white-space: nowrap;
}

#uploads-btn {
  white-space: nowrap;
  text-transform: uppercase;
  color: #3fd999 !important;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 3px;
  margin: 5px;
  text-decoration: none;

  /* border: 1px solid #3fd999; */
}

.uploads-btn {
  text-decoration: none !important;

  /* border: 1px solid #3fd999; */
}

.uploads-btn-season {
  padding: 20px 50px;
  font-size: 20px;
  background: none;
  border: 1px solid #3fd999 !important;
  border: none;
  cursor: pointer;
  transition: 200ms;
  border-radius: 5px;
  white-space: nowrap;
  display: flex;
  text-align: center;
  /* width: 50%; */
  /* margin-top: 20px; */
  margin-right: 10%;
}

.uploads-btn:hover {
  background: black;
}

.uploads-btn-season:hover {
  background: black;
}

#seasons .season-info {
  color: white;
  font-size: 18px;
  text-align: center;
}

#seasons .filter-container {
  margin-top: 30px;
}

.primary-btn {
  padding: 10px 15px;
  color: white !important;
  background: #3fd999;
  font-size: 16px;
  cursor: pointer;
  border: solid 1px #3fd999;
}

.secondary-btn {
  padding: 10px 15px;
  color: white;
  border: solid 1px #3fd999;
  font-size: 16px;
  background: transparent;
  cursor: pointer;
}

.card-check.approved {
  accent-color: #3fd999;
}

.popup-hit-image-container {
  height: 300px;
  display: flex;
  justify-content: center;
}

.card-check.approved + label {
  color: #3fd999;
  cursor: pointer;
  transition: 200ms;
}

.card-check.pending {
  accent-color: #fda130;
}

.card-check.pending + label {
  color: #fda130;
  cursor: pointer;
  transition: 200ms;
}

.card-check.approved + label:hover,
.card-check.pending + label:hover {
  text-decoration: underline;
}

.popup-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.245);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
}

.sub-heading {
  font-size: 20px;
  color: white;
  text-align: center;
}

#login-container .login-wrapper {
  font-size: 16px;
}

#login-container .login-wrapper #login-link {
  color: #3fd999;
}

#login-container {
  flex-direction: column;
}

.upload-list-table table {
  font-size: 16px;
  background: none;
  color: white;
  border: #3fd999;
}

.upload-list-table .table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: solid 1px #3fd999 !important;
}

.view-card-btn {
  line-height: 5;
}

.delete-confirm-popup {
  flex-direction: column;
  font-size: 16px;
}

#confirm-popup .card-upload-area,
#confirm-popup .card-upload-area div {
  display: block;
  font-size: 15px;
}

.popup-button-container {
  display: flex !important;
  flex-direction: row !important;
}

button {
  transition: 300ms;
}

button.primary-btn:hover,
button.secondary-btn:hover {
  transform: scale(1.1);
}

.card-check-label:hover {
  cursor: pointer;
  color: #3fd999;
  text-decoration: underline;
}

input[type="number"],
select,
input[type="text"] {
  width: 100%;
  padding: 16px 8px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  color: black;
  border-radius: 10px;
  font-size: 18px;
}

.addplayer {
  text-align: center;
  min-width: 155px;
}

.card-upload-area.delete {
  flex-direction: column;
  font-size: 16px;
}

.card-upload-area.delete div {
  flex-direction: row;
}

.view-card-btn.add-player {
  width: 100%;
  line-height: 2 !important;
  margin-bottom: 10px;
}

.view-card-btn.remove-player {
  line-height: inherit;
  height: 50px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-wrapper * {
  margin: 5px;
}

.input-field-wrapper > div {
  margin: 2px;
}

.input-field-wrapper {
  width: 400px;
  justify-content: space-between;
  align-items: flex-end;
}

.input-field-wrapper .sign-in-form-wrapper {
  width: auto;
}

.sign-in-form-wrapper.card-name {
  width: auto;
}

.sign-in-form-wrapper.total-cards {
  max-width: 100px;
}

.remove-btn .remove-player {
  margin: 8px 0;
  height: 63px;
}

.ml-1 {
  margin-left: 1em;
}

.inner-season .ac {
  border: solid 1px #04aa6d;
  margin-bottom: 15px;
  transition: 200ms;
  transform: scale(1.01);
  border-radius: 5px !important;
}

.inner-season .ac a:hover {
  color: white;
  text-decoration: none;
}

.inner-season .ac-label:after {
  background: #3fd999;
}

.inner-season .ac:hover {
  transform: scale(1.03);
}

#player-cards-img {
  margin: auto;
  width: 90%;
}

@media only screen and (max-width: 768px) {
  .top-hits-card-details {
    color: white;
    font-size: 16px;
    padding-right: 10px;
  }

  .data {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
    padding-bottom: 40px;
  }

  .card-container {
    width: 100%;
  }

  .checklists-wrapper {
    width: 100%;
    margin: 0;
    padding: 5px;
  }

  .hit-card-image {
    width: auto;
    height: 120px;
    padding: 0;
    object-fit: contain;
    transition: 200ms;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #hit-card-2 {
    width: 100% !important;
    padding: 0;
    margin: 0;
  }

  .published-by {
    display: none;
  }

  #latest-hits-notes {
    display: none;
  }

  .uploads-btn-season {
    margin: 0;
    margin-top: 20px;
  }

  #hit-img-container {
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner-hit-img-container {
    width: 33%;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 2px #04aa6d;
  }

  .top-html-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    font-size: 1rem !important;
  }

  .hit-btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .filter-container .submit-button {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }

  .filter-container input.filter {
    margin-bottom: 0;
  }

  .back-to-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .back-to-wrapper a {
    margin-left: 0;
    margin-top: 10px;
  }

  .filter-button-container button {
    margin: 5px;
  }

  .filter-button-container {
    display: flex;
    width: 100%;
  }
}

.button-pagination .page:first-child {
  background: none;
  border: solid 1px #3fd999;

  color: white;
}

.button-pagination .page {
  background: #3fd999;

  font-size: 16px;
  margin: 10px;
  padding: 10px 15px !important;
  transition: 200ms;
  cursor: pointer;
}

.button-pagination .primary-btn:hover {
  background: #04aa6d !important;
  color: white !important;
}

.button-pagination {
  justify-content: center;
  flex-wrap: wrap;
}

.dropzone {
  display: flex !important;
  align-items: center !important;
  border: solid 1px #04aa6d;
  border-radius: 0 !important;
  padding: 5px;
}

p#upload {
  background: #04aa6d;
  text-shadow: none !important;
  font-weight: 500;
  padding: 10px 20px;
}

@media only screen and (max-width: 768px) {
  p#upload {
    padding: 5px 10px;
  }

  .card-upload-area img[alt~="Preview"] {
    width: inherit !important;
    object-fit: contain;
  }
}

.popup-overlay {
  z-index: 99999 !important;
}

#confirm-popup{
z-index: 999999;}

.seasons-uploads .uploaded-container {
  width: auto;
  height: -webkit-fill-available;
  margin: 10px;
}

.top-checkbox {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.top-checkbox label {
  margin: 0 0 0 10px !important;
}

.top-checkbox input {
  margin: 0 !important;
  width: 20px;
  height: 20px;
}

.player-add-edit input {
  margin: 0 !important;
}

.player-add-edit input[type="checkbox"] {
  height: 20px;
  width: 20px;
}

.player-add-edit .sign-in-form-wrapper {
  margin-top: 20px;
}

.top-player-options input {
  margin: 0 !important;
}

.top-player-options {
  background: #3d3d3d;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  align-items: center;
}

.top-player-options label {
  margin: 0;
  margin-left: 10px;
}

.sign-in-form-wrapper .color-input {
  padding: 10px !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

#confirm-popup {
  z-index: 999999999 !important;
}

.checkoff-popup .link a{
  color:#fda130;
}

.popup-checkOffs .list-container li:hover, .popup-checkOffs .list-container li.active{
  color: #fda130;
}

