.wheels-container {
  background-color: #1f1f1f;
  padding: 0.1% 2% 0% 2%;
  margin: 0;
  border-radius: 10px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  .premium-section-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    color: #fff;
    width: inherit;
    max-width: inherit;
    width: 100%;
    border-radius: 20px;

    z-index: 100 !important;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 30px;
    margin-top: 10px;
    .premium-section-top-logo img {
      width: 150px;
    }
    .razz-filler-header {
      font-size: 4rem;
    }
  }
}
