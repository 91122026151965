.contact-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 50px;
}
.contact-header {
  transform: uppercase;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  padding-bottom: 10px;
  border-bottom: 2px solid #04aa6d;
  font-size: 3rem;
}
.contact-p {
  color: #fff;
  font-size: 2rem;
  font-family: "Work Sans", sans-serif;
  margin: 0;
  padding-top: 25px;
  padding-bottom: 25px;
}
.contact-form {
  background: #222224;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 3%;
  /* height: 300px; */
  color: black;
}

.contact-input {
  color: #fff;
  font-size: 2rem;
  font-family: "Work Sans", sans-serif;
  color: black;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50% !important;
  padding-left: 1% !important;
  padding-top: 1% !important;
}
.contact-submit-btn {
  background: #04aa6d;
  color: #fff;
  font-size: 2rem;
  width: 20%;
  height: 60px;
  border: none;
  margin: 0;
  border-radius: 5px;
  font-family: "Work Sans", sans-serif;
  margin-top: 20px;
  /* box-shadow: 2px 2px 2px black; */
}
#contact-textarea {
  height: 150px;
  color: black;
  width: 100% !important;
  padding-left: 1%;
  padding-top: 1%;
}
.email-success {
  color: #fff;
  font-size: 2rem;
  font-family: "Work Sans", sans-serif;
  margin-left: 20px;
  display: none;
}
.submit-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 800px) {
  .contact-input {
    width: 100% !important;
  }
  .contact-submit-btn {
    width: 100%;
  }
}
