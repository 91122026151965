.premium-section {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.premium-section-container {
  background: #222224;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  margin-top: 0 !important;
  border: 1px solid #222224;
}
.image-fluid:hover {
  cursor: pointer;
}
.razz-payment-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  /* border: 2px solid red; */
}
.new-icon-wrapper {
  margin-left: 10px;
}
.signup-login-links {
  font-size: 1.8rem;
  color: white;
}
.signup-login-links:hover {
  text-decoration: none;
}
#signup-login-link {
  color: #3fd999;
}
.premium-section-top-logo img {
  width: 150px;
}
.premium-section-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  color: #fff;
  width: inherit;
  max-width: inherit;
  width: 100%;
  border-radius: 20px;
  /* background: #222224; */
  /* border: 1px solid red; */

  z-index: 100 !important;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 30px;
  margin-top: 10px;
}
.seller-logo-img {
  height: 80px;
  border-radius: 50%;
  width: 80px;
}
.btn-column-wrapper {
  display: flex;
  flex-direction: column;
}
#razz-btn {
  border-radius: 0;
}
#reset-btn-razz {
  margin-top: 40px;

  border: 1px solid #fda130 !important;
  color: #fda130 !important;
}
.payment-toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.payment-p {
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 1.4rem;
}
.list-payment-container {
  position: relative;
}
.paid-column-wrapper {
  color: black;
  display: none;
  height: 450px;
  width: 40%;
  margin-left: 60%;
  position: absolute;
  max-height: 450px;
  background: white;

  color: black;
  border: none;
  z-index: 1;
  line-height: 35px;
  font-family: "Work Sans", sans-serif;
  max-height: 450px;
  font-size: 27px;
  padding: 15px;
  overflow-y: scroll;
  font-weight: bold;
}
.paid-column-wrapper-multi {
  color: black;
  width: 50%;
  margin-left: 50%;
  position: absolute;

  background: white;

  border: none;
  z-index: 1;

  overflow-y: scroll;
  display: none;
  padding-left: 2px;
  padding-right: 2px;
  height: 450px;
  font-size: 2rem;
  line-height: 3.6ch;
  font-family: "Work Sans", sans-serif;
  color: black;
  font-weight: bold;
}
.paid-column-wrapper-multi2 {
  color: black;
  width: 50%;
  margin-left: 50%;
  position: absolute;

  font-size: 2rem;

  background: white;
  font-family: "Work Sans", sans-serif;
  color: black;
  border: none;
  z-index: 1;

  overflow-y: scroll;
  display: none;
  padding-left: 2px;
  padding-right: 2px;
  height: 450px;
  font-size: 2rem;
  line-height: 3.6ch;
  font-family: "Work Sans", sans-serif;
  color: black;
  font-weight: bold;
}
.paid-column-wrapper-multi3 {
  color: black;
  width: 50%;
  margin-left: 50%;
  position: absolute;

  font-size: 2rem;

  background: white;
  font-family: "Work Sans", sans-serif;
  color: black;
  border: none;
  z-index: 1;

  overflow-y: scroll;
  display: none;
  padding-left: 2px;
  padding-right: 2px;
  height: 450px;
  font-size: 2rem;
  line-height: 3.6ch;
  font-family: "Work Sans", sans-serif;
  color: black;
  font-weight: bold;
}
.paid-column-wrapper-multi4 {
  color: black;
  width: 50%;
  margin-left: 50%;
  position: absolute;

  font-size: 2rem;

  background: white;
  font-family: "Work Sans", sans-serif;
  color: black;
  border: none;
  z-index: 1;

  overflow-y: scroll;
  display: none;
  padding-left: 2px;
  padding-right: 2px;
  height: 450px;
  font-size: 2rem;
  line-height: 3.6ch;
  font-family: "Work Sans", sans-serif;
  color: black;
  font-weight: bold;
}
.list-container {
  max-width: 100%;
}
.item-wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  width: 98%;
}
.item-input {
  padding-left: 2px;
  padding-right: 2px;
  font-size: 2rem;
  line-height: 3.8ch;
  font-family: "Work Sans", sans-serif;
  color: black;
  font-weight: bold;
}
#item-span {
  margin-left: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 5px;
  margin-right: 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.randomizer-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.randomizer-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .randomizer-slider {
  background-color: #3fd999;
}

input:focus + .randomizer-slider {
  box-shadow: 0 0 1px #3fd999;
}

input:checked + .randomizer-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
#reset-btn {
  border: 1px solid #fda130 !important;
  color: #fda130 !important;
}
/* Rounded sliders */
.randomizer-slider.round {
  border-radius: 34px;
}

.randomizer-slider.round:before {
  border-radius: 50%;
}
.razz-textarea1 {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  line-height: 3.6ch;
  background-image: linear-gradient(
    transparent,
    transparent calc(3.6ch - 1px),
    darkgray 0px
  );
  background-size: 100% 3.6ch;
  width: 100%;
  height: 450px;
  padding-left: 2px;
  padding-right: 2px;
  height: 450px;
  font-size: 2rem;
  line-height: 3.6ch;
  font-family: "Work Sans", sans-serif;
  color: black;
  font-weight: bold;
}
.razz-textarea2 {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  line-height: 3.6ch;
  background-image: linear-gradient(
    transparent,
    transparent calc(3.6ch - 1px),
    darkgray 0px
  );
  background-size: 100% 3.6ch;
  width: 100%;
  height: 450px;
}
.razz-textarea3 {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  line-height: 3.6ch;
  background-image: linear-gradient(
    transparent,
    transparent calc(3.6ch - 1px),
    darkgray 0px
  );
  background-size: 100% 3.6ch;
  width: 100%;
  height: 450px;
}
.razz-textarea4 {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  line-height: 3.6ch;
  background-image: linear-gradient(
    transparent,
    transparent calc(3.6ch - 1px),
    darkgray 0px
  );
  background-size: 100% 3.6ch;
  width: 100%;
  height: 450px;
}
.randomize-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.razz-counter {
  margin-top: 5px;

  color: white;
  font-size: 2rem;
}
.razz-timestamp {
  margin-top: 5px;

  color: darkgray;
  font-size: 1.5rem;
}
.standslider {
  padding: 0px 100px 25px 100px;
  background: #222224;
}

.premium-section-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.premium-tool-wrapper .dice-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.premium-bottom-panel {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.premium-dice-wrapper-desc {
  font-size: 25px;
  color: white;
  margin-bottom: 30px;
  text-align: center;
}

.premium-dice-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.premium-tool-wrapper .dice-wrapper .dice {
  width: fit-content;
  display: flex;
  margin: auto;
}

.premium-arrow-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.premium-arrow-button {
  z-index: 0;
  padding-top: -100px;
}
#font-change-btn {
  /* border: 1px solid red !important; */
  background: transparent !important;
  border-radius: 0;
}
#font-change-btn:hover {
  background: black !important;
}

.font-change-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  flex-wrap: wrap;
}

.font-change-wrapper input {
  height: 40px;
  font-size: 16px;
  border: 1px solid black;
  padding: 4px;
  margin-right: 5px;
  width: 80px;
}

.curtain-speed-change-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.curtain-speed-change-wrapper input {
  height: 40px;
  font-size: 16px;
  border: 1px solid black;
  padding: 4px;
  margin-right: 5px;
  width: 80px;
}

.curtain-speed:first-child {
  margin-right: 40px;
}

.curtain-speed-label {
  font-size: 20px;
  color: white;
  margin-bottom: 5px;
}

.premium-arrow-button:disabled {
  cursor: not-allowed;
}

.premium-dice-actions {
  flex: 1;
}

.premium-dice-actions button {
  padding: 8px !important;
  font-size: 20px !important;
}
#counter-label {
  /* border: 1px solid red; */
  font-size: 3rem;
}

.premium-dice-actions-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.premium-dice-actions-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.premium-section-info p {
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-left: 10px;
}
.razz-filler-header {
  font-size: 4rem;
}
.premium-text-area {
  color: black;
  font-family: "Work Sans", sans-serif;
  /* border-radius: 10px; */
  height: 450px;
  max-height: 100%;
  width: 100%;
  font-size: 27px;
  padding: 20px;
  resize: none;
  background-position: 0 0;
  background-repeat: no-repeat;
  /* position: relative;  <- its messing up curtain code, so used static instead */
  position: static;
  /* z-index: -10 !important; */
  /* white-space: nowrap; */
  border: none;
  line-height: 35px;
  line-height: 35px;
  font-family: "Work Sans", sans-serif;
  max-height: 450px;
  font-size: 27px;
  padding: 15px;
  font-weight: bold;
}
.watermark-img {
  height: 300px;
  width: 300px;
  pointer-events: none;
  border-radius: 50%;
  position: absolute;
  opacity: 0.1 !important;
  filter: alpha(opacity=5) !important; /* For IE8 and earlier */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: none;
  z-index: 0;
  visibility: visible;
}
.hide-breaker-img {
  height: 300px;
  width: 300px;
  border-radius: 50% !important;
  position: absolute;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important; /* For IE8 and earlier */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  visibility: hidden;
}
/* body.ie7 #trans,
body.ie8 #trans {
  background: transparent url(/images/opacity40_black.png);
} */

.header {
  background: #2c2c2c !important;
  color: #fff;
  z-index: 99 !important;
  width: 100%;
}

/* Page content */
.content {
  padding: 0 10%;
}

#text-input {
  position: relative; /* for absolute child element */
  display: inline-block; /* to take the width of the input */
}
.randomize-counter-p {
  margin-left: 10px;
  font-size: 2rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.pull-down-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.pull-down-icon:hover {
  color: black;
}
.curtain-payment-container {
  display: flex;
}
.hide-curtain {
  top: 0;
  left: 0;
  height: 0;
  right: 0;
  background: #2c2c2c;
  /* border: 10px solid red; */
  margin-top: -1px;
  z-index: 10;
  visibility: hidden;
  border: none;
  border-radius: 0;
  width: 100%;
}
.wrap-curtain-text {
  position: relative;
}

.premium-bottom-text-panel {
  width: 50%;
}

.premium-bottom-info {
  width: 50%;
  padding-top: 45px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.toggle-img {
  max-width: 10vw;
  max-height: 36vh;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10vh;
}

.activeLink {
  border-bottom: 1px solid white;
}

/* MOBILE FIRST */

.randomizer-gemint-logo {
  grid-column: 1;
  grid-row: 1;
  font-size: 0.6em;
}

.randomizer-seller-logo {
  /* grid-column: 1 / 3;
  grid-row: 1; */
  display: flex;
  /* justify-content: flex-end;
  align-items: flex-end; */
}

.labels-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
}

.label-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

/* .hide-breaker-img {
  height: 300px;
  width: 300px;
  border-radius: 50% !important;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
} */

@media (max-width: 1052px) {
  .premium-dice-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .premium-section-info {
    margin-top: 10px;
  }

  .standslider {
    padding: 10px;
  }
  .payment-p {
    color: white;
    font-family: "Work Sans", sans-serif;
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .razz-filler-header {
    font-size: 3rem;
  }

  .seller-logo-img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #reset-btn-razz {
    margin: 0;
  }
  #razz-btn {
    border-radius: 30px;
  }
  .premium-section-top {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .premium-section-info p {
    font-size: 15px;
  }

  .labels-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .label-wrapper {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .premium-dice-wrapper-desc {
    font-size: 20px;
  }

  .premium-dice-actions button {
    margin-bottom: 5px !important;
  }

  .premium-bottom-info {
    padding-left: 10px;
  }

  .font-change-wrapper input {
    width: 100%;
    margin-bottom: 5px;
  }
  .premium-bottom-panel {
    flex-direction: column;
  }
  .premium-bottom-text-panel {
    width: 100%;
  }
  .premium-bottom-info {
    width: 100%;
    padding-top: 0px;
  }

  .razz-counter {
    display: flex;
    justify-content: center;
  }
  .razz-timestamp {
    display: flex;
    justify-content: center;
  }
}
