.pricing .pricing-header {
  font-size: 40px;
  color: #fff;
  font-family: "Work Sans", sans-serif;

  margin-bottom: 2rem;
  /* margin-top: 30px; */
  margin-top: 0;
  text-align: center;
  margin-top: 50px;
}
.pricing {
  margin-top: 0 !important;
}

.pricing .pricing-header span {
  color: #3fd999;
  font-size: 50px;
}

.pricing .pricing-desc {
  font-family: "Work Sans", sans-serif;

  text-align: center;
  color: white;
  font-size: 20px;
  margin-bottom: 50px;
}

.pricing-tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 40px;
}

.pricing-tabs .pricing-tab {
  width: 30%;
  border-radius: 20px;
  color: white;
}

.pricing-tab {
  background-color: black;
}

.pricing-tab.current {
  background-image: linear-gradient(
    rgb(63, 217, 153),
    rgba(63, 217, 153, 0.03)
  );
}

.pricing-tab-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pricing-tab-price {
  font-size: 25px;
  margin-bottom: 20px;
}

.pricing-free-sign-up {
  background: #3fd999;
  border: 2px solid #fff;
  color: #000;
  padding: 10px 0;
  border-radius: 10px;
  font-weight: 700;
  transition: 0.5s;
  cursor: pointer;
  margin-bottom: 30px;
  font-size: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-free-sign-up:hover {
  transition: 0.5s;
  background: #000;
  color: #3fd999;
}

.pricing-premium-sign-up {
  background-color: white;
  border: 2px solid #fff;
  color: #000 !important;
  padding: 10px 0;
  border-radius: 10px;
  font-size: 25px;
  font-weight: 700;
  transition: 0.5s;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-option-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 30px;
}

.payment-option-buttons .pricing-free-sign-up {
  margin-right: 15px;
}

.payment-option-buttons button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
}
.pricing-tab ul {
  list-style: none;
  margin-bottom: 40px;
}

.pricing-tab .price-action-panel {
  font-size: 25px;
  text-align: center;
  width: 100%;
  font-size: 25px;
  text-align: center;
  border: 1px solid white;
  border-radius: 25px;
  padding: 8px 0px;
  margin-bottom: 10px;
}

.pricing-tab .price-action-panel.cancel-button {
  background-color: white;
  color: black;
  border: 1px solid #3fd999;
  cursor: pointer;
}

.pricing-tab ul li {
  font-size: 20px;
  margin-bottom: 10px;
}

@media (max-width: 1052px) {
  .pricing-tabs {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .pricing-tabs .pricing-tab {
    width: 80%;
    margin: auto;
  }

  .pricing-tab.free,
  .pricing-tab.monthly {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .pricing-tabs {
    padding: 0 10px;
  }
  .pricing-tabs .pricing-tab {
    max-width: 90%;
    width: 90%;
    margin: auto;
  }

  .pricing-tab.free,
  .pricing-tab.monthly {
    margin-bottom: 30px;
  }

  .pricing-tab ul {
    list-style: none;
    margin-bottom: 20px;
  }

  .pricing-tab ul li {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .payment-option-buttons {
    flex-direction: column;
  }

  .payment-option-buttons .pricing-free-sign-up {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .pricing .pricing-header {
    font-size: 30px;
  }
  .pricing .pricing-desc {
    font-size: 18px;
  }
}
