#user-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

#user-dropdown-img {
  border-radius: 50%;
  margin-right: 5px;
  height: 50px;
  width: 50px;
}

#x-wrapper{
  width: 100% !important;
  justify-content: flex-start !important;
}



.split-menu{
  z-index: 1;
}

#x-wrapper {
  /* border: 1px solid red; */
  margin-top: -20px;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

#x {
  color: white;
  width: 10%;
  font-size: 2.4rem;
}

#x:hover {
  cursor: pointer;
}

/* 1200 px  */
@media (max-width: 1200px) {
  .nav-link-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }

  .gemint-logo {
    display: none;
  }

  /* #checklists-desktop {
    display: none;
  } */
}
#x-wrapper {
  display: flex !important;
}

@media (max-width: 1052px) {
  #checklists-desktop {
    display: none;
  }
}

.beta-icon {
  width: 30px;
}

.top-menu-bar,
.bottom-menu-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-top-menubar{
  display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 1052px) {
  .tab-option {
    height: 80px;
    width: auto;
  }
  .tab-option {
    flex-direction: column;
    padding: 5px 7px !important;
    font-size: 12.5px !important;
  }

  .bottom-menu-bar {
    padding: 0;
  }

  .navbar-brand {
    padding: 0;
  }

  .nav-link.dropdown-toggle.user-action {
    width: auto !important;
  }

  .dropdown-menu {
    position: absolute !important;
    background: #222 !important;
    border: solid 1px white !important;
    left: -70% !important;
  }

  .tab-option {
    height: auto;
    padding-top: 0 !important;
  }

  .tab-option a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .tab-option img {
    margin-right: 0 !important;
  }
}

.split-menu {
  width: 100%;
}

.tab-option {
  padding-top: 0 !important;
}

/* #user-dropdown {
  min-width: auto !important;
  margin: 0 !important;
} */

.top-menu-bar {
  min-height: 35px;
}

.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px;}

#fillIcon .cls-1{
  fill: white !important;
  stroke: none !important;
}

.tab-option:hover #fillIcon .cls-1, .tab-option.active #fillIcon .cls-1{
  fill: #3fd999 !important;
}



.tab-option svg {
  margin-right: 3px;
  width: 2em;
}

.tab-option:hover a{
  color: #3fd999;
}



.tab-option.active svg *, .tab-option:hover svg * {
  stroke: #3fd999;
}

.tab-option .check-off-icon{
  fill: white;
}

.tab-option:hover .check-off-icon, .tab-option.active .check-off-icon{
  fill: #3fd999;
}

.tab-option svg{
  transition: 300ms;
}

.tab-option:hover svg{
  transform: scale(1.1);
}

.tab-option a{
  padding: 3px !important;
}

.accordion-body {
  color: white;
}

.accordion-body ul{
  font-size: 20px;
}

@media only screen and (max-width: 600px){
   section{
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 360px){
  .tab-option a{
    font-size: 10px;
  }
}

@media only screen and (min-width: 600px){
  svg.check-off-icon{
    padding: 4px;
  }
}

.advertismentImage{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.advertismentImage img{
  width: 100% !important;
}

