.terms-section {
  width: 70%;
  margin: auto;
}
.terms-p {
  color: white;
  font-size: 2rem;
  margin-top: 50px;
}
.terms-section-header {
  font-size: 50px;
}

@media (max-width: 1052px) {
  .terms-section {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .terms-section {
    width: 90%;
    font-size: 25px;
  }
}
