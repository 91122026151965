@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  background: #222;
  font-family: "Mulish", sans-serif;
}

.bottom-bar {
  background-color: #151414;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 90px;
  padding: 10px 30px;
  font-size: 14px;
  position: fixed;
  bottom: 15px;
  width: auto;
  max-width: fit-content;
  left: 0;
  right: 0;
  margin: auto;
}

.tab-option {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 10px 15px;
  border-bottom: solid 1px rgb(187, 187, 187);
  transition: 200ms;
  cursor: pointer;
}

.tab-option.active {
  border-bottom: solid 3px #3fd999;
}

.tab-option a {
  color: white;
  text-decoration: none !important;
  font-weight: bold;
}

.tab-option:hover {
  border-bottom: solid 3px #3fd999;
}

.tab-option img {
  margin-right: 3px;
  width: 2em;
}

.seasons-scroller {
  display: flex;
  overflow-x: scroll;
  padding: 10px 0;
  cursor: grab;
}

*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: transparent; /* or add it to the track */
}

*::-webkit-scrollbar-thumb {
  background: #424242;
  border-radius: 50px;
}

.season {
  border: solid 1px #3fd999;
  min-width: 25%;
  padding: 20px;
  border-radius: 10px;
  color: white;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
}

.season.active {
  background: #3fd999;
  color: #222;
  font-weight: 600;
}

.season-details {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.season-players {
  border: solid 1px #808080;
  border-radius: 10px;
  padding: 20px;
}

.seasonPlayerContainer{
  width: 70%;
}

.season-cards {
  border: solid 1px #808080;
  border-radius: 10px;
  padding: 20px;
}

.seasonCardsContainer{
  width: 30%;
}

.player {
  max-height: 120px !important;
  max-width: 120px !important;
  min-height: 120px !important;
  min-width: 120px !important;
  margin-right: 10px;
  padding: 10px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px gray;
  background: linear-gradient(45deg, #8b1818, #450101);
}

.current-player {
  height: 180px;
  width: 180px;
  margin: auto;
  padding: 10px;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3.5px #3fd999;
  margin-bottom: 20px;
  background: linear-gradient(45deg, #3b990c, #01453b);
}

.player.active {
  border: solid 1px #3fd999;
}

.player-image {
  width: 100%;
  border-radius: 100%;
}

.player-name {
  color: white;
  text-align: center;
  font-size: 16px;
}

.players-scroller {
  display: flex;
  overflow-x: scroll;
  padding: 10px 0;
}

.season-cards input {
  background: #393939;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 99px;
  padding: 10px 20px;
  font-size: 16px;
  color: white !important;
}

.season-cards ul {
  list-style: none;
  color: white;
  padding: 0;
}

.season-cards ul li {
  margin: 20px 0;
}

.season-cards .player-cards {
  max-height: 400px;
  font-size: 16px;
  overflow-y: scroll;
}

.season-cards .player-cards ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.season-cards .player-cards ul li:first-child {
  margin-top: 0 !important;
}

.seasons {
  text-align: center;
}

.search-season input {
  background: #393939;
  width: 100%;
  border: none;
  border-radius: 99px;
  padding: 10px 20px;
  font-size: 16px;
  color: white !important;
  margin-bottom: 0 !important;
}

.season-name {
  font-size: 42px;
  word-break: keep-all;
  color: white;
  font-weight: 700;
}

.search-player {
  text-align: center;
  position: relative;
}

.search-player input {
  background: #393939;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 99px;
  padding: 10px 20px;
  font-size: 16px;
  color: white !important;
}

.secondary-menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: auto;
  width: 100%;
}

.checklist-container {
  padding: 0 20px;
}

.nav-link.dropdown-toggle.user-action {
  min-width: none !important;
}

.upload-confirmation > div{
  cursor: pointer;
}

.see-all-text{
  color: #FDA130;
  font-style: italic;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

/* Mobile */
@media only screen and (max-width: 800px) {

  .see-all-text{
    font-size: 12px;
  }

  .bottom-bar {
    bottom: 0;
    border-radius: 0;
    width: 100%;
    margin: 0;
    padding: 5px 15px;
  }

  .upload-confirmation > div{
    font-size: 12px !important;
  }
  /* .button-group{
    flex-direction: column !important;
  } */

  .button-group a{
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding: 10px 5px !important;
  }

  .player-popup.upload-popup{
    padding: 10px !important;
  }

  .player-popup.upload-popup>h2{
    font-size: 16px;
  }

  .upload-icon{
    margin-top: 0 !important;
  }

  #upload-player .card-upload-area div{
    margin: 0 !important;
  }

  .upload-icon{
    max-width: 100px !important;
  }

  .season-cards .player-cards {
    height: 320px;
  }

  .season-name h2 {
    font-size: 28px !important;
  }

  .player-cards {
    width: fit-content;
  }

  .season {
    min-width: 60%;
  }

  /* .player {
    min-width: 30%;
  } */

  .player {
    max-height: 70px !important;
    max-width: 70px !important;
    min-height: 70px !important;
    min-width: 70px !important;
    margin-right: 10px;
  }

  .current-player {
    height: 130px;
    width: 130px;
  }

  .season-players {
    height: inherit;
    padding: 5px;
  }

  .season-cards {
    padding: 5px;
  }

  .season-cards .player-cards {
    font-size: 14px;
  }

  .season-cards ul li {
    margin: 10px 0;
  }

  .season-details {
    grid-gap: 5px;
  }

  .players-scroller {
    max-width: fit-content;
  }

  .season-cards img {
    width: 100%;
  }

  .seasonPlayerContainer{
    width: 60%;
  }

  .seasonCardsContainer{
    width: 40%;
  }



  .season-cards .player-cards ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }

  input#search-season,
  input#search-player {
    width: 100%;
  }
}

.season-name h2 {
  font-size: 32px;
  font-weight: bold;
  margin: 20px 0;
}

/* carasousel */

.carouselwrapper {
  overflow: hidden;
  background: var(--color-lightgrey);
  position: relative;
}
.carousel {
  width: var(--carouselWidth);
  margin: 0 0 0 var(--carouselLeftMargin);
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.carousel.prev {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  animation-name: prev;
}
.carousel.next {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  animation-name: next;
}
.carousel li {
  padding: 50px;
  text-align: center;
  width: calc(var(--cardWidth) - var(--card-margin) * 2);
  box-sizing: border-box;
}
.ui {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: calc(100% - var(--card-margin) * 2);
  justify-content: space-between;
  padding: var(--card-margin);
  z-index: 100;
}
.ui button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-ui);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--color-white);
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
  border: 0;
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.ui button:hover {
  background: var(--color-ui-hover);
}
.ui button:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 1);
}
.card {
  box-shadow: 0px 1px 2px 0px var(--card-shadow);
  border-radius: 4px;
  margin: var(--card-margin);
  background: var(--color-white);
}
.card .material-icons {
  font-size: 4.8rem;
}
.card p {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0;
}
@keyframes next {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px + var(--cardWidth)));
  }
}
@keyframes prev {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px - var(--cardWidth)));
  }
}

.material-icons {
  background: white;
  height: 30px;
  width: 30px;
  border-radius: 30px;
}

.players-scroller {
  margin-bottom: 10px;
}

.season-player-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  width: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  background: rgba(0, 0, 0, 0.278);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  max-height: 90vh;
  border-radius: 10px;
  border: 1px solid white;
  backdrop-filter: blur(10px);
}

#success-popup .card-name, .popup-overlay .card-name, 
#Card-show .card-name, .popup-overlay .card-name {
  color: #3fd999 !important;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: solid 1px #3fd999;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.cards-container {
  background: #222;
  height: auto;
  padding: 10px;
  font-size: 16px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  overflow: auto;
}

.card-input-check {
  width: 5em;
  justify-content: center;
}

.card-input-check label {
  margin: 0;
}

.follow {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.follow-button, .follow-buttonfollowed, .follow-buttonunfollowed {
  border: solid 1px #3fd999;
  background: black;
  color: white;
  font-size: 16px;
  padding: 5px 15px 5px 40px;
  border-radius: 25px;
  position: relative;
  height: auto;
}

/* .follow-button::before {
  content: "+";
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1;
  border: solid 1px #3fd999;
  border-radius: 50px;
  position: absolute;
  left: 0;
  top: 0;
} */

.shareImage {
  width: 45px;
  margin-left: 10px;
}

@media (max-width: 800px) {
  .season-player-popup {
    padding: 25px 10px;
    width: 96%;
  }

  .player-name {
    font-size: 12px;
  }

  .cards-container {
    justify-content: center;
    max-height: 40vh;
  }

  .season-player-popup {
    top: 50%;
  }

  .search-season {
    width: 100% !important;
  }
}

.leaders-container .search-season {
  position: relative;
  width: 40%;
  margin: auto;
}

 .search-season {
  position: relative;
  margin: auto;
}

.search-results li {
  list-style: none;
  color: white;
  text-align: left;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

.search-results li:hover {
  background: #3fd999;
}

.search-results {
  position: absolute;
  background: rgb(45, 45, 45);
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
  overflow: auto;
  max-height: 40vh;
  z-index: 99;
}

.player-upload-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  width: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  background: #121212;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  max-height: 90vh;
  border-radius: 10px;
  border: 1px solid #fda130;
  width: 35%;
}

.upload-desc {
  font-size: 2em;
  color: white;
  font-weight: 600;
  text-align: center;
}

#ebay-link {
  padding: 5px 20px;
  color: white;
  background: black;
  border-radius: 99px;
  border: 1px solid #fda130;
}

.or-choice {
  text-align: center;
  color: #c4c4c4;
  font-size: 2em;
  margin: 0 10px;
}

.player-upload-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-upload-area .upload-back,
.player-upload-area .upload-front,
.player-upload-area .card-front,
.player-upload-area .card-back {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 1.2em;
  align-items: center;
}

.player-upload-area .card-front,
.player-upload-area .card-back {
  margin: 10px;
}

.player-upload-area img {
  width: 9em;
}

.upload-confirmation {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.upload-confirmation > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}

.upload-confirmation > div > span {
  font-size: 30px;
}

@media (max-width: 800px) {
  .player-upload-popup {
    width: 75%;
    padding: 10px;
  }

  #upload-player .button-container {
    flex-direction: column;
    gap: 10px;
  }
}

.post-upload-actions {
  text-align: center;
}

.post-upload-actions button {
  background: black;
  border: solid 1px #3fd999;
  color: white;
  padding: 10px;
  font-size: 14px;
}

.post-upload-actions button.back {
  border-radius: 10px 0 0 10px;
}

.post-upload-actions button.view {
  border-radius: 0 10px 10px 0;
}

.ebay-popup-button {
  background: #04aa6d;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
}

.player-popup.upload-popup {
  background: rgba(0, 0, 0, 0.278);
  backdrop-filter: blur(10px);
  border: solid 1px #fda130;
}

.player-popup h2 {
  width: 100% !important;
}

.card-upload-area {
  justify-content: space-between;
}

#upload-player .card-upload-area div {
  width: 50%;
}

.dropzone {
  border: none;
}

input.ebay-link {
  background: #393939;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 99px;
  padding: 10px 20px;
  font-size: 16px;
  color: white !important;
  margin-top: 10px;
  margin-bottom: 0;
}

#upload-card-desc {
  padding: 0;
  color: white;
  box-shadow: none;
  border: none;
  font-size: 16px;
}

#ebay-upload.player-upload {
  min-width: 30%;
}

#ebay-upload .card-upload-area {
  display: flex;
  flex-direction: column;
}

#ebay-upload .card-upload-area .uploaded-images {
  display: flex;
  gap: 20px;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
}

#ebay-upload .card-upload-area .uploaded-images img {
  width: 12rem;
  height: auto;
  object-fit: cover;
}

.tab-option.active a{
  color: #3fd999;
}

.tab-option:hover a img{
  transition: 200ms;
}

.tab-option:hover a img{
 transform: scale(1.2);
}

.player-cards ul li:hover, .player-cards ul li.active{
  color: #3fd999;
}

.error-text{
  font-size: 16px;
}


/* #success-popup{
  display: flex !important;
} */

#success-popup .player-popup,
#Card-show .player-popup{
position: fixed;
left: 50%;
top: 50%;
z-index: 999999;
width: 35%;
transform: translate(-50%, -50%);
max-width: 1000px;
background: rgba(0, 0, 0, 0.278);
padding: 40px;
display: flex;
flex-direction: column;
justify-content: center;
overflow-y: scroll;
max-height: 90vh;
border-radius: 10px;
border: 1px solid white;
-webkit-backdrop-filter: blur(10px);
backdrop-filter: blur(10px);}


#success-popup .card-upload-area div,
#Card-show .card-upload-area div{
  width: 100%;
}

#success-popup .button-group,
#Card-show .button-group{
display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;}

#success-popup .button-group a,
#Card-show .button-group a{
  color: black;
  background: #3fd999;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 5px;
  transition: 300ms;
  cursor: pointer;
  text-decoration: none;
}

#success-popup .button-group a:hover,
#Card-show .button-group a:hover{
  background: #04aa6d;
}

#success-popup .uploadedImages,
#Card-show .uploadedImages{
  display: flex !important;
  gap: 10px;
  flex-direction: row;
}

.uploadedImages img{
  width: 50%;
}

/* #Card-show{
  display: flex;
} */

.tab-option>a{
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.players-list{
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.players-list ul{
  list-style: none;
  font-size: 12px;
  color: white;
  padding: 0;
}

#see-all-player .season-player-popup, #see-all-cards .season-player-popup{
  padding: 10px;
}

/* .submitted-notes{
  display: none;
} */

.players-list ul li{
  border: solid 1px #3fd999;
  padding: 10px 15px;
  margin-bottom: 5px;
  border-radius: 10px;
  position: relative;
}

.players-list ul li:after{
  content: "+";
  color: black;
  background: #3fd999;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 0 10px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}


.seasons .season h2::after,
.leaders-container .season h2::after{
  content: "|";
  font-size: inherit;
  margin-left: 1px;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#add-season-popup .follow-button::before{
  display: none;
}

#add-season-popup .follow-button span:first-child{
content: "+";
    color: white;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    aspect-ratio: 1;
    border: solid 1px #3fd999;
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: 0;}

.checklist-container .seasons .follow-button{
  list-style: none;
  width: fit-content;
}

.checklist-container .seasons  .follow-button>span{
content: "+";
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border: solid 1px #3fd999;
  border-radius: 50px;
  position: absolute;
  left: 0;
  top: 20px;
  cursor: pointer;
  font-size: 16px !important;
  transition: 300ms !important;

  
}

a.checklist-link{
  color: #3fd999 !important;
}

.checklist-container .seasons  .follow-button>span.followed{
background: #3fd999;
    color: black;
}
/* .checklist-container .seasons  .follow-button>span:hover{
  width: fit-content;
  text-align: right;
  transition: 300ms;
}



.checklist-container .seasons  .follow-button>span::before{
  content: 'follow';
  opacity: 0;
  transition: 300ms;
}

.checklist-container .seasons  .follow-button>span:hover::before{
  opacity: 1;
  transition: 300ms;
} */


.checklist-container .seasons  .season{
  margin: 0;
}

.checklist-container .seasons  .follow-button{
  padding: 0;
  height: auto;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
}

.checklist-container .seasons{
  position: relative;
}
  
  .tab-option{
    padding: 0 !important;
  }

  .tab-option a{
    padding: 10px 15px;
  }


  #login-popup{
    z-index: 999999999 !important;
  }

  @media only screen and (min-width: 600px){
    .checklist-container .seasons{
      width:40%;
      }

    
  }

  

    .seasons-container{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .checkOffList .player-name{
      text-align: left;
    }

.checklist-instruction{
  text-align: center;
  color: #FDA130;
  font-size: 13px;
}

.checkOffList .primary-btn{
  color: black !important;
}