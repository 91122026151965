.sign-up-premium-section .payment-wrapper {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

.sign-up-premium-section .payment-wrapper form {
  width: 100%;
}

.payment-wrapper-title {
  font-size: 50px;
  text-align: center;
}

.payment-wrapper-price {
  font-size: 25px;
  text-align: center;
  margin-bottom: 30px;
}

.payment-wrapper-details ul {
  list-style: none;
  margin-bottom: 40px;
}

.payment-wrapper-details ul li {
  font-size: 20px;
  margin-bottom: 10px;
}

.sign-up-premium-section .payment-wrapper button {
  margin-top: 20px;
}

.sign-up-premium-section h1 {
  font-size: 50px;
}

.sign-up-premium-section p {
  font-size: 25px;
}

.sign-up-premium-section {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}

.error-panel {
  color: red;
  font-size: 15px;
  text-align: left;
  margin-top: 20px;
}

.sign-up-premium-section input {
  font-size: 15px;
}

@media (max-width: 768px) {
  .sign-up-premium-section {
    width: 95%;
  }

  .sign-up-premium-section h1 {
    font-size: 25px;
  }

  .sign-up-premium-section p {
    font-size: 15px;
  }

  .sign-up-premium-section .payment-wrapper{
    width: 100%;
    padding: 10px;
  }

  .sign-up-premium-section .payment-wrapper-details ul{
    padding: 0;
  }

  .sign-up-premium-section form{
    padding: 0;
  }
}
