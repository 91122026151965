.mailchimp-container {
  font-size: 25px;
  font-family: "Work Sans", sans-serif;
  color: black;
  text-align: center;
}

.mailchimp {
  font-size: 25px;
  font-family: "Work Sans", sans-serif;
}
.mailchimp-header {
  font-size: 30px;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  color: white;
}
.email {
}
