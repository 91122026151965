/* Popup style */
.popup-box {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 111;
}

.box {
  position: relative;
  width: 500px;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #303030;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.forgot-password-dialog-header {
  font-size: 20px;
  text-align: left;
  color: white;

}

.popup-box .primary-btn{
  background: #3fd999 !important;
  color: black !important;
  border-radius: 10px;
  border: none !important;
  padding: 10px 25px;
  cursor: pointer;
}

.box input {
  font-size: 14px;
  width: 80%;
  border-radius: 5px;
  margin-bottom: 20px;
}

.reset-link-sent {
  font-size: 20px;
  text-align: left;
  color: white;
}

.box button {
  background-color: #fda130;
  border: 2px solid #fda130;
  color: black;
  padding: 5px 10px;
  font-weight: 900;
  box-sizing: border-box;
  cursor: pointer;
}

.box button:disabled {
  background-color: gray;
  border-color: gray;
  cursor: not-allowed;
}

.popup-title {
  font-size: 25px;
  color: white;
  font-weight: bold;
}

.popup-link {
  font-size: 20px;
  color: white;
}

.popup-link span {
  color: #3fd999;
}

.popup-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popup-actions .yes-btn {
  background-color: #3fd999;
  border: 1px solid #3fd999;
  color: black;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.popup-actions .no-btn {
  background-color: black;
  border: 1px solid #3fd999;
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .box {
    width: 90%;
    box-sizing: border-box;
  }
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
}

.close-icon div {
  width: 16px;
  height: 2px;
  background-color: white;
  transform-origin: 0px;
  border-radius: 10px;
  transition: all 0.3s linear;
}

.close-icon div:nth-child(1) {
  transform: rotate(45deg);
}

.close-icon div:nth-child(2) {
  transform: translateX(0);
  opacity: 0;
}

.close-icon div:nth-child(3) {
  transform: rotate(-45deg);
}
