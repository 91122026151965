.seller-settings {
  width: 90%;
  margin: auto;
  background: #1e1e20;
  border-radius: 30px;
  padding: 30px 20px;
  margin-bottom: 50px;
}

.seller-settings-header {
  font-size: 50px;
  color: white;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
}

.seller-settings .support-panel {
  width: 80%;
  padding: 50px 60px;
  position: relative;
  margin: auto;
  border: 1px solid #3fd999;
}

.seller-settings .support-panel .support-panel-text {
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 25px;
}

.seller-settings .support-panel .support-panel-text a {
  color: #3fd999;
}

.seller-settings .support-panel .support-link {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #3fd999;
  text-decoration: none;
  font-size: 25px;
  cursor: pointer;
}

.seller-settings .support-panel .portal-link {
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: #3fd999;
  text-decoration: none;
  font-size: 25px;
  cursor: pointer;
}

.seller-settings-form .profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #3fd999;
}

.seller-settings-form-label {
  font-size: 25px;
  margin-bottom: 15px;
}

.seller-settings-form input {
  height: 60px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid white;
  text-align: center;
  font-size: 20px;
  color: black;
}

.seller-settings-form input:focus {
  border-color: #3fd999;
}

.seller-settings-form .username-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.seller-settings-form .username-input-wrapper input {
  margin-right: 15px;
}

.seller-settings-randomizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid white;
  padding-top: 15px;
}

.seller-settings-randomizer h1 {
  color: white;
  font-size: 50px;
  font-family: "Work Sans", sans-serif;
}

@media (max-width: 768px) {
  .seller-settings .support-panel {
    width: 90%;
    padding: 20px 10px;
    padding-bottom: 50px;
    position: relative;
    margin: auto;
    border: 1px solid #3fd999;
  }

  .seller-settings .support-panel .support-panel-text {
    font-size: 15px;
  }

  .seller-settings-form .username-input-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .seller-settings-form .username-input-wrapper input {
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .seller-settings-form input {
    height: 60px;
    width: 100%;
    margin-bottom: 10px;
  }
}

/* profile css */
.user-profile {
  display: flex;
  color: white;
  width: 90%;
  margin: auto;
}

/* .account-panel {
  width: 30%;
  margin-right: 20px;
} */

.user-info {
  width: 100%;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 50px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-account-info{
width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;}

.profile-overview {
  width: 60%;
  
  
}

.border-box{
  backdrop-filter: blur(10px);
  border: solid 1px white;
  border-radius: 10px;
  padding: 20px;
  margin-right: 20px;
}

.profile-details{
  font-size: 14px;
}

.user-profile-pic{
  width: 40%;
  display: flex;
  justify-content: center;
}

.account-variables *{
  text-align: left !important;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
  content: "\f030";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
}

.avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.name,
.bio-head {
  font-size: 3em;
  font-weight: bold;
  text-align: center;
}

.join-date,
.bio-content {
  text-align: center;
  font-size: 16px;
}

hr.solid {
  margin: 10px auto;
  height: 0.5px;
}

.profile-head {
  font-weight: bold;
  border-bottom: solid 1px white;
  padding-bottom: 10px;
}

.hits-count {
  font-size: 20px;
  margin: 15px 0;
}

.hit-container {
  display: flex;
  margin: 30px 0;
}

.hit-images {
  width: 20%;
  cursor: pointer;
}

.hit-info {
  width: 50%;
  font-size: 16px;
  padding-left: 20px;
}

.hit-images img.image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 2px 3px 12px 1px #3fd999;
  position: relative;
  
}

.hit-images{
  position: relative;
}

.season-search{
  border: solid 1px #3fd999;
  background: black;
  font-size: 14px;
  padding:  5px 10px;
  border-radius: 30px;
  color: white;
}

.labelling-text{
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

#add-season-popup .player-popup{
  border-color: #3fd999;
}

.checklist-list ul{
  list-style: none;
  margin-top: 10px;
  color: white;
  padding: 0;
  font-size: 14px;
  display: grid;
  grid-template: "1fr 1fr";
  grid-gap: 10px;
}

.checklist-list ul li{
  /* padding: 5px 10px; */
  border: solid 1px white;
  border-radius: 10em;
  position: relative;
  cursor: pointer;
}

.cta-add{
  cursor: pointer;
}

.checklist-list ul li.followed{
  border-color: #3fd999;
  
}

.checklist-list ul li.followed:hover{
  transition: 200ms;
}

.checklist-list ul li.followed:hover:after{
  content: "Followed";
  position: absolute;
  top: -5px;
  right: -5px;
  background: black;
  border: solid 1px #FDA130;
  padding: 1px 3px;
  border-radius: 10px;
}

.hit-images:before {
  content: "+1";
  position: absolute;
  top: -5px;
  left: -5px;
  border: solid 1px #3fd999;
  border-radius: 70px;
  padding: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  z-index: 10;}

.view-hits {
  text-align: center;
  padding: 10px 15px;
  font-size: 16px;
  color: white;
  background: #3fd999;
  cursor: pointer;
  font-weight: 600;
}

.view-button {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1000px) {

  .user-profile,
  .hit-container {
    display: block;
  }

  .account-panel,
  .profile-overview,
  .hit-images,
  .hit-info {
    width: 100%;
    margin-bottom: 10px;
  }

  .hit-info {
    padding: 10px;
  }
}

#imagePreview {
  width: 100%;
  width: 192px;
  height: 192px;
  object-fit: cover;
  border-radius: 100%;
  border: 4px solid #3fd999;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.edit-profile-btn,
.save-info {
  white-space: nowrap;
  text-transform: uppercase;
  color: black !important;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 3px;
  margin-top: 20px;
  padding: 10px 25px;
  font-size: 20px;
  background: #3fd999;
  border: none;
  cursor: pointer;
  transition: 200ms;
  border-radius: 5px;
}

#editprofile {
  justify-content: center;
  align-items: center;
}

.user-info-popup {
  background-color: rgb(50, 50, 50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  max-height: 90vh;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.user-info-popup label {
  color: white;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}

.user-info-popup input,
.user-info-popup textarea {
  margin: 0;
  font-size: 18px;
  border-radius: 5px;
  padding: 10px;
  font-weight: 600;
}

.view-button a:hover {
  color: white !important;
}

.error-msg p {
  color: red;
  font-size: 14px;
  font-style: italic;
  text-align: center;
}

.user-profile{
  flex-direction: column;
}

.checklist-of-container {
  overflow-y: scroll;
  max-height: 300px;
  padding: 5px;
}

.profile-seasons {
  height: 94%;
}

.top-account-info{
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 768px) {
  .profile-seasons{
    min-height: 200px;
  }

  .checklist-list ul{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .checklist-list ul li{
    padding-left: 35px;
  }

  .hit-container .player-name{
    font-size: inherit;
  }

  .hit-container .hit-info .card-name{
    margin-top: 0 !important;
  }


}

.dropzone > p{
  text-shadow: none !important;
}

.user-profile.not-logged-in{
  width: 100%;
  margin: 0;
}

.login-in-overlay-cta{
  padding: 20px;
}

.login-desc{
  font-size: 24px !important;
}

#add-season-popup .follow-button span.followed{
  background: #3fd999;
  color: black;
}

.user-profile.not-logged-in{
  z-index: 0;
}