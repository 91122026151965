@font-face {
  src: url("fonts/matroska-rppg6-webfont.woff2") format("woff2"),
    url("fonts/matroska-rppg6-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-family: "Work Sans", sans-serif;
}

#create-account-btn {
  margin-bottom: 50px;
}

.container {
  font-family: "Work Sans", sans-serif;
  width: 90%;
  padding: 0;
}

.full-width {
  width: 100%;
}

.flex-container-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-container-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text {
  font-size: 25px;
  text-align: center;
  width: 100%;
}

table {
  background-color: white;
  color: black;
  font-size: 0.6em;
  text-align: center;
}

table td {
  min-width: 10vw;
}

.no-decoration a {
  text-decoration: none;
  color: white;
}

.highlight {
  color: #fda130;
}

textarea {
  font-weight: bold;
  line-height: 1.4;
  font-size: 1.1em;
}

.logo {
  font-family: "Work Sans", sans-serif;
  margin: 0;
  text-decoration: none;
  color: white;
}

.highlight-mint {
  color: #3fd999;
}

#randomizer-label {
  margin-left: 5%;
}

.form-wrapper {
  /* border: 1px solid red; */
}

#navbarSupportedContent > ul > li.nav-item.dropdown > ul > li:nth-child(2) {
  margin-bottom: 0px !important;
}

.containers {
  width: 1320px !important;
  margin: auto;
}

ul.slick-dots > li > button {
  font-size: 0px !important;
}

#root
  > div.container
  > div
  > div:nth-child(4)
  > div
  > div
  > div.col-md-5.d-flex.align-items-center
  > div
  > h1
  > span {
  color: #3fd999 !important;
  font-size: 50px !important;
}
#root > div.container > div > div:nth-child(4) > div > div > div.col-md-6 {
  text-align: right;
}
/* NAV */

.advRandomizer {
  margin-bottom: 5%;
}

.randomizer-title-lg {
  line-height: 92%;
}

.randomizer-form {
  margin: auto auto;
}

#randomizer-input-lg {
  padding: 0;
  margin: 0;
  width: 95%;
  font-size: 1rem !important;
}
#randomizer-input {
  /* border: 10px solid blue; */
  width: 100%;
  font-size: 2rem;
  height: 400px;
}

.randomizer-form-lg textarea {
  font-size: 1.2em !important;
  margin-left: 1.5% !important;
}

.text-lg {
  font-size: 1.4em;
  font-weight: 500;
}

.text-sm {
  font-size: 0.6em;
  font-weight: 300;
}

#hover:hover {
  cursor: pointer;
}

.follow-us-header {
  margin-right: 10px;
}

.insta-icon {
  border: 1px solid white;
  padding: 0.5%;
  border-radius: 50%;
}
.insta-icon:hover {
  transform: scale(1.15);
  color: #f1f1f1;
}
.wrapper {
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: space-between;

  width: 100%;
}
.logo-randomizer-wrapper {
  display: flex;
}

.description {
  font-weight: 300;
  color: #e5e5e5;
  text-align: left;
  font-size: 1.75rem;
  width: 130%;
  margin-bottom: 75px;
}

/* Buttons */

.btn-primary {
  background-color: #fda130;
  border: 2px solid #fda130;
  color: black;
  padding: 2vh 2vw;
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 1vh;
}

.btn-primary:hover {
  background-color: #ca8127;
  border: 2px solid #ca8127;
  cursor: pointer;
}

.btn-primary-disabled {
  background-color: transparent;
  border: 2px solid #fda130;
  color: #fda130;
  padding: 2vh 2vw;
  text-transform: uppercase;
  font-weight: 900;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.btn-group button {
  background-color: #04aa6d; /* Green background */
  border: 1px solid green; /* Green border */
  color: white; /* White text */
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
}

.btn-group button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: #3e8e41;
}

/* Form */

.form {
  text-align: left;
  max-width: 40vw;
}

.form button {
  margin-top: 3vh;
}

.form-title {
  font-size: 0.9em;
  /* margin-top: 3vh; */
  margin-bottom: 0.8vh;
  width: 80%;
}

.btn-dark {
  background-color: #2c2c2c;
  border: 2px solid #2c2c2c;
  color: white;
  padding: 2vh 3vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
}
.btn-dark:hover {
  cursor: pointer;
}

.btn-gray {
  background-color: #808080;
  border: 2px solid #808080;
  color: white;
  padding: 2vh 3vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
}

.btn-outline {
  background: transparent;
  border: 2px solid #3fd999;
  border-radius: 30px;
  color: #3fd999;
  padding: 2vh 2vw;
  margin-right: 1vw;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  padding: 10px 35px 10px 35px;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
}

.btn-outline:hover {
  color: white;
  background: black;
}

.btn-outline:disabled {
  cursor: not-allowed;
}

.text-outline {
  color: white;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  font-family: "Work Sans", sans-serif;
  max-width: 100%;
}

.text-outline-desktop {
  color: white;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  font-size: 85px;
  font-family: "Work Sans", sans-serif;
  margin-top: 8vh;
  clear: both;
}

.text-outline-secondary {
  color: #808080;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #808080, 100%;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  text-align: center;
  /* margin-top: 10vh; */
  padding-bottom: 50px;
  margin: 0;
}

.sport-card {
  z-index: -1;
  position: absolute;
  bottom: 20%;
  right: 0;
  opacity: 0.6;
  backdrop-filter: blur(1px);
}

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}
.labels-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.counter-label {
  font-size: 1.2rem !important;
  color: white;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.counter-number {
  margin-left: 20px;
  border-radius: 50%;
  padding: 7%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timestamp-label {
  margin-top: 10px;
  font-size: 0.7rem;
  color: white;
}

/* The sidebar links */
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* The button used to open the sidebar */
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s; /* If you want a transition effect */
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}

/* Small devices */
@media only screen and (max-width: 1280px) {
  .form-title {
    text-align: center;
    font-size: 0.7em;
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .hide-lg {
    display: none;
  }

  .container {
    font-family: "Work Sans", sans-serif;
  }

  .text-outline-desktop {
    visibility: hidden;
  }

  .sport-card {
    visibility: hidden;
  }

  .text-outline {
    font-size: 1em;
  }

  .form {
    /* margin-left: 15%; */
    /* margin-top: 10vh; */
  }

  .input {
    width: 80%;
  }

  .logo-description-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
  }

  .text-outline-mobile {
    display: none;
    color: white;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 30px;
    font-family: "Work Sans", sans-serif;
    white-space: nowrap;
  }
}

.player, .player-cards ul li, .card-input-check *{
  cursor: pointer !important;
}

body > iframe[style*='2147483647']{
  display: none;
}

.split-menu{
  z-index: 1;
}

.shareImage{
  cursor: pointer;
  transition: 300ms;
}

.shareImage:hover{
  transform: scale(1.2);
}

.navbar-brand img{
  width: 100%;
  max-width: 200px;
}