.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.needHelp{
  font-size: 16px;
  margin-top: 10px;
}

.needHelp a{
  color: #3fd999;
  cursor: pointer;
  text-decoration: none;
}

.login-page {
  color: white;
}

.login-page h1 {
  font-size: 50px;
  margin-bottom: 15px;
}

.login-page .login-page-sub-header {
  font-size: 20px;
}

@media (max-width: 1052px) {
  .login-page h1 {
    font-size: 25px;
  }
}

.sign-in-form-wrapper {
  max-width: 400px;
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .sign-in-form-wrapper {
    max-width: 400px;
    width: 100%;
  }

  .sign-in-actions{
    padding: 10px;
  }

  /* #create-account-btn{
    margin: 10px;
  } */
}

.sign-in-form-wrapper input {
  font-size: 24px;
}

.sign-in-form-wrapper label {
  font-size: 15px;
  padding: 0;
  color: white;
}

.sign-in-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  max-width: 300px;
  width: 300px;
}

#create-account-btn {
  margin-top: 0;
  background: #3fd999;
  width: 100%;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

#create-account-btn:disabled {
  background: gray;
  color: black;
  cursor: not-allowed;
}

.sign-in-actions .forgot-password {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
  padding: 1vh 1vw;
  background-color: transparent;
  border: none;
  margin-top: -40px;
}
