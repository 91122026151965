.tier-randomizer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* background: #222224; */
  border-radius: 20px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  /* padding-left: 2%;
  padding-right: 2%; */
  /* border: 1px solid red; */

  margin-bottom: 30px;
  margin-top: 10px;
}
#tier-input {
  border: 3px solid red;
}

.tier-randomizer .dice-wrapper {
  width: 100%;
}

.tier-randomizer .nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tier-randomizer .dropdowns {
  position: relative;
  cursor: pointer;
  font-size: 25px;
}

.custom-dropdowns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tier-randomizer .dropdowns .dropdown {
  padding: 6px 10px;
  background: #1f1f1f;
}

.tier-randomizer .dropdowns .dropdown p {
  margin: 0;
  text-align: center;
}

.tier-randomizer .dice {
  width: fit-content;
}

.download-csv {
  display: none;
}

@media (max-width: 768px) {
  .tier-randomizer .nav-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .custom-dropdowns {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .locked-actions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
  }
}
