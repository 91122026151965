.check-page-container {
  padding: 0 20px;
  font-size: 14px;
}

.checkoff-container {
  margin-top: 20px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.checkoffs-list li{
  cursor: pointer;
}

.checkOffCards {
  width: 30%;
  color: white;
  text-align: center;
}

.checkOffs,
.checkOffListContainer {
  border: solid 1px #c4c4c4;
  border-radius: 10px;
  padding: 5px;
}

.recentCheckOffs {
  width: 70%;
  color: white;
  text-align: center;
}

.containerDesc {
  margin-bottom: 10px;
}

.checkOffs #search-card {
  background: #393939;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 99px;
  padding: 10px 20px;
  font-size: 16px;
  color: white !important;
}

.checkoffs-list ul {
  list-style: none;
  padding: 0;
  display: block;
  grid-template: "1fr 1fr";
}

.checkoffs-list {
  max-height: 300px;
  font-size: 12px;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 0 !important;
}

.checkoffs-list li {
  border: solid 1px #c4c4c4;
  font-size: 12px;
  border-radius: 60px;
  padding: 8px 2px;
  position: relative;
  line-height: 1.1;
  margin: 2px;
}

.advertisement-image{
  display: flex;
  justify-content: center;
}

.checkOffList {
  max-height: 360px;
  font-size: 12px;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 0 !important;
}

.checkOffList .card {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  text-align: left;
}

.card-desc {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

@media only screen and (min-width: 800px) {
  .checkoffs-list li {
    font-size: 16px;
    margin: 5px;
    padding: 10px;
  }

  .card-image {
    width: 20%;
  }

  .card-desc {
    font-size: 16px;
  }
}

.checkoff-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999999;
  width: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  background: rgba(0, 0, 0, 0.278);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  max-height: 90vh;
  border-radius: 10px;
  border: 1px solid white;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-size: 16px;
}

.checkoff-name {
  color: white;
  font-weight: bold;
  text-align: left;
  font-size: 24px;
}

div.link {
  color: #fda130;
}

.popup-checkOffs ul {
  list-style: none;
  color: white;
  padding: 0;
  max-height: 300px;
  overflow: auto;
}

.popup-checkOffs ul li {
  margin-bottom: 10px;
}

.popupCheckOffContainer {
  margin-top: 20px;
}

.popupCheckOffContainer {
  display: flex;
  gap: 10px;
}

.popupCheckOffContainer .popup-checkOffs,
.popupCheckOffContainer .check-off-search-list {
  width: 50%;
}

input#search-card,
input#althlete-name {
  background: #393939;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 99px;
  padding: 10px 20px;
  font-size: 16px;
  color: white !important;
  margin-top: 0;
  margin-bottom: 10px;
}

.check-off-search-list ul {
  color: white;
  list-style: none;
  padding: 0;
  max-height: 250px;
  overflow: auto;
}

.check-off-search-list ul li {
  margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .checkoff-popup {
    top: 50%;
  }

  .checkoff-popup {
    padding: 25px 10px;
    width: 96%;
  }
}

.card::after {
  content: "+1";
  position: absolute;
  top: 0px;
  left: 0px;
  border: solid 1px #3fd999;
  border-radius: 70px;
  padding: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  z-index: 0;
}

.popup-checkOffs ul li, .check-off-search-list ul li{
  cursor: pointer;
}

#error-popup, #show-player{
  z-index: 99999999 !important;
}

#checkoff-popup{
  z-index: 2 !important;
}

#idCardUploads{
  z-index: 3 !important;
}

#pending-approval, #upload-player{
  z-index: 4 !important;
}

.card-image{
  box-shadow: 2px 3px 12px 1px #3fd999;
  margin: 10px;
}

.checkoffs-list ul li:hover, .checkoffs-list ul li.active{
  color: #fda130;
  border-color: #fda130;
}

.checkOffList{
  z-index: -1;
}

/* .checkOffList .card img{
  z-index: -1;
} */

@media only screen and (max-width: 600px) {
  .checkOffCards{
    width: 40%;
  }
  .recentCheckOffs{
    width: 60%;
  }
}

.containerDesc{
  font-weight: bold;
}

.follow-buttonfollowed{
  border: solid 1px #3fd999;
  background: black;
  color: white;
  font-size: 16px;
  padding: 5px 15px 5px 40px;
  border-radius: 25px;
  position: relative;
}

.follow-buttonfollowed::before {
  content: "✔";
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border: solid 1px #3fd999;
  border-radius: 50px;
  position: absolute;
  left: 0;
  top: 0;
}

.checkOffs{
  height: 90%;
}

.checkOffList .card img{
  aspect-ratio: 1/1;
  object-fit: cover;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  /* .checkOffList .card{
    flex-direction: column;
  } */

  .checkOffList .card img{
    width: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .checkoff-container{
    flex-direction: column;
  }

  .checkoff-container div{
    width: 100%;
  }

  .containerDesc.accordionToggle{
  background: #3fd999;
    padding: 10px 15px;
    text-align: center;
    color: black;
    display: flex;
    justify-content: center;
  border-radius: 10px;}

  .checkOffCards{
    font-size: 22px;
  }

  .follow-button{
    font-size: 10px;
    padding: 2px 15px 2px 30px;
  }

  .follow-button::before{
    width: auto;
    height: 100%;
    /* padding-bottom: 100%; */
    aspect-ratio: 1;
  }

  .checkoffs-list{
    max-height: 110px;
  }

  .checkOffList{
    max-height: fit-content;
  }

  .checkOffs #search-card{
    height: 25px;
    margin-bottom: 0;
  }

  .checkOffList .card{
    flex-direction: column;
  }
}

.accordin-toggle{
  padding: 3px;
}

.accordin-toggle img{
  width: -webkit-fill-available;
  width: inherit;
}

.checkOffList .card{
  border: none;
}

.accordin-toggle svg{
  width: 100%;
}

.checkOffs{
  overflow: hidden;
}

.checkOffs .checkoffs-list .follow-button::before{
  display: none;
}

.checkOffs .checkoffs-list .follow-button span:first-child{
  content: "+";
    color: white;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    aspect-ratio: 1;
    border: solid 1px #3fd999;
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: 0;
}

.checkOffs .checkoffs-list .follow-button span:first-child:hover{
  font-size: 0;
}

.checkOffs .checkoffs-list .follow-button span:first-child:hover::before{
  content: "Follow";
  font-size: 16px;
}

.checkOffs .checkoffs-list .follow-button span.followed:first-child:hover::before{
  content: "Unfollow";
  font-size: 16px;
}

.checkOffs .checkoffs-list .follow-button span.followed{
  background: #3fd999;
  font-weight: 600;
  color: black;
}

.follow-response{
  font-size: 16px !important;
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: fixed;
  bottom: 5px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: fit-content;
  border-radius: 10px;
  background: #3fd999;
  z-index: 9999999999;
  padding: 5px 15px;
  color: black;
  transition: 200ms;
}

.checkOffList .player-name {
  color: white;
  font-size: 16px !important;
  font-weight: 400;
}

.uploadedBy{
  margin-top: 10px;
}

.checkOffList .season-name {
  color: white;
  font-size: 16px !important;
  font-weight: 400;
}

.uploadedBy{
  color: white;
}

.collectedPoints{
  font-weight: bold;
  font-size: 18px;
  margin-top: 15px;
}

.card-desc a{
  color: white;
}

.accordin-toggle{
  display: none;
}

/* .containerDesc{
 display: block;
} */




@media only screen and (max-width: 600px){
.containerDesc{
  display: flex;
  /* justify-content: space-between; */
}


.accordin-toggle{
  display: block;
  background: black;
  cursor: pointer;
  border: solid 1px #3fd999;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-left: 10px;
}

.checkOffCards .checkOffs{
  height: 0;
  padding: 0;
  border: none;
  transition: 600ms;
}



.checkOffCards .checkOffs.toggle{
  height: auto;
  padding: 5px;
  border: solid 1px #c4c4c4;
}

}

.seasonSearchIcon{
  height: 16px;
  margin: 0 5px 5px 0 ;
}

.checkoff-popup .followDiv{
  display: flex;
  gap: 10px;
}

.checkoff-popup .followDiv .follow-button{
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

@media only screen and (max-width: 375px){
  .followDiv{
    padding-right: 20px;
  }
}

.containerDesc.withSearch{
  display: flex;
}

.containerDesc.withSearch input.search{
  background: black;
  border: solid 1px #3fd999;
  color: white;
  border-radius: 50px;
    padding: 1px 10px 1px 28px;
    width: 100%;
    height: 100%;
}

.searchInputContainer{
  position: relative;
  width: 20em;
  margin-left: 30px;
}

.searchInputContainer .searchImg{
  filter: invert(1);
  position: absolute;
  left: 3px;
  height: -webkit-fill-available;
  padding: 4px;
  aspect-ratio: 1;
}


@media only screen and (max-width: 600px) {
  .searchInputContainer .searchImg{
    height: 25px;
    top: 10px;
  }

  .checkoffsheading{
    display: none;
  }

  .containerDesc.withSearch{
    flex-direction: column;
  }

  .searchInputContainer{
    margin-left: 0;
  }

  .searchInputContainer .search{
    height: 40px !important;
  }
}

.load-more.primary-btn{
  border-radius: 10px;
  font-weight: 600;
}