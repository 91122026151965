.landing-page {
  color: white;
}

.landing-page-header {
  margin-top: 150px;
  margin-bottom: 150px;
}

.landing-page-header h4 {
  font-size: 30px;
}
.signup-login-links {
  font-size: 1.8rem;
}
.signup-login-links:hover {
  text-decoration: none;
}
#signup-login-link {
  color: #3fd999;
}
.new-icon-wrapper {
  margin-left: 10px;
}
.landing-page-title {
  text-transform: uppercase;
  color: white;
  font-size: 60px;
}
#randomizer-input {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  line-height: 35px;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  height: 400px;
  position: relative;
  color: black;
  font-family: "Work Sans", sans-serif;
  padding: 5px !important;
  margin: 0 !important;
  z-index: 0;
  background: #faf9f6;
}
.paid-text-area-container {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}
.free-column-wrapper {
  color: black;
  display: none;
  /* padding-right: 10px; */
  height: 400px;
  width: 40%;
  margin-left: 60% !important;
  position: absolute;
  /* z-index: 1000 !important; */
  /* border-radius: 10px; */

  overflow-y: scroll;
  background: #faf9f6;
  border-radius: 15px;
  max-height: 400px;
  font-size: 2rem;
  color: black;
  font-family: "Work Sans", sans-serif;
  border: none;
  z-index: 0;
  line-height: 35px;
  padding: 5px !important;
  font-weight: bold;
}
.landing-right {
  display: flex;
  flex-direction: column;
}
.sponsorship-wrapper {
  /* border: 5px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.presented-by {
  /* border: 1px solid blue; */
  font-size: 1.4rem;
  font-family: "Work Sans", sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
}
.sponsor-img-wrapper {
  /* border: 1px solid orange; */
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (max-width: 500px) {
  .sponsor-img-wrapper {
    width: 80%;
  }
}
.arrow-wrapper {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -10px;
}

.arrow:hover {
  cursor: pointer;
}
.hide-curtain {
  margin-top: 15px;
  top: 0;
  left: 0;
  height: 0;
  right: 0;
  border-radius: 10px;
  background-color: #1f1f21 !important;
  background-color: #2c2c2c !important;
  background-color: black !important;
}
.hide-breaker-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 450px; */
  height: 405px !important;
}
.hide-breaker-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px !important;
  height: 300px;

  /* height: 200px;
  width: auto; */
  visibility: hidden;
  border-radius: 0 !important;
  /* border-radius: 50%; */
}
.sponsor-img {
  width: 250px;
  height: 100px;
  background-image: none;
}
@media (max-width: 500px) {
  .sponsor-img {
    width: 100%;
    height: 150px;
  }
}

.landing-page-title span {
  font-weight: bold;
  font-style: italic;
  color: #3fd999;
  font-size: 60px;
}

.landing-age-desc-top {
  font-size: 20px;
}

.landing-age-desc-bottom {
  color: #3fd999;
  font-size: 20px;
  text-transform: uppercase;
}

.get-premium-button {
  border: 3px solid #3fd999;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  color: #3fd999;
  padding: 10px 35px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  transition: 0.5s;
}

.landing-page-action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.dice-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.dice-container .randomizer {
  margin-right: 50px;
}

#randomizer-title {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  border-radius: 0px;
  color: white;
  width: 100%;
  height: 50px;
}
#reset-btn {
  border: 1px solid #fda130 !important;
  color: #fda130 !important;
}
.dice-container textarea.homte {
  /* background-color: #1f1f21 !important; */
  border: 0px !important;
  padding: 20px !important;
  margin-top: 20px;
  width: 100% !important;
  height: 500px;
  max-width: 100% !important;
  font-size: 20px;
  color: white;
  max-height: 100% !important;
  resize: none;
  background: #faf9f6;
  color: black;
  /* border-radius: 20px; */
  max-height: 400px;
  font-size: 2rem;
  color: black;
  font-family: "Work Sans", sans-serif;
  border: none;
  z-index: 10;
  line-height: 35px;
  padding: 5px !important;
  font-weight: bold;
}

.randomizer-bottom {
  color: white;
  margin-top: -10px;
}

.randomize-counter label,
.time-stamp label {
  font-size: 15px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.randomize-counter label span {
  font-size: 24px;
}

.roll-dice-wrapper h2 {
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100%;
  font-size: 20px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  color: white;
}

.landing-page-slider .slider-header {
  font-size: 50px;
  margin-top: 100px;
}

.slider .text-left {
  font-size: 25px;
}

.slider .testi-n {
  font-size: 40px;
  color: #3fd999;
}

.landing-page-tiers .landing-page-tiers-left {
  max-width: 350px;
}

.landing-page-tiers .landing-page-tiers-left h4 {
  font-size: 25px;
}

.landing-page-tiers .landing-page-tiers-left h1 {
  font-size: 30px;
}

.landing-page-tiers .landing-page-tiers-left h1 span {
  color: #3fd999;
}

.landing-page-tiers .landing-page-tiers-left p {
  font-size: 20px;
}

.price-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.price-section img {
  width: 200px;
  margin-bottom: 40px;
}

.price-section p {
  font-size: 15px;
  margin-bottom: 8px;
}

.price-section .abtn {
  width: fit-content;
}

@media (max-width: 1052px) {
  .dice-container {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .dice-container .randomizer {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .landing-page-tiers {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .landing-page-tiers-left {
    width: 90%;
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .landing-page-header {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .landing-page-header h4 {
    font-size: 15px;
  }

  .landing-page-title span {
    font-size: 40px;
  }

  .landing-page-title {
    font-size: 40px;
    padding: 0 12px;
  }

  .landing-age-desc-top {
    font-size: 15px;
    padding: 0 12px;
  }

  .landing-age-desc-bottom {
    font-size: 15px;
  }

  .dice-container .randomizer {
    width: 90%;
  }

  .roll-dice-wrapper {
    width: 90%;
  }

  .dice-wrapper button {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .roll-dice-wrapper .abtn {
    margin: 0 !important;
  }

  .landing-page-slider .slider-header {
    font-size: 30px;
  }

  .slider .text-left {
    font-size: 20px;
  }

  .slider .testi-n {
    font-size: 30px;
  }

  .price-section {
    padding-left: 12px;
    padding-right: 12px;
  }

  .randomizer-bottom {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 22px;
  }
}
