body {
  /* background-image: url('../images/background.png'); */
  /* font-family: "Inter", sans-serif; */
  font-family: "Work Sans", sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
}
a {
  text-decoration: none;
}

/*navbar start*/
a.nav-item.nav-link:hover {
  color: #3fd999;
  transition: 0.5s;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

a.abtn:hover {
  background: #000;
  color: #fff;
  transition: 0.5s;
}

.abtn2 {
  border: 3px solid #2c2c2c;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  color: white;
  padding: 10px 68px 10px 68px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  background: #2c2c2c;
}
.nav-link {
  color: white;
  margin-left: 20px;
  margin-right: 20px;
}
#navbarSupportedContent > ul > li.nav-item.dropdown > ul > li > a {
  color: white;
  background: black;
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  padding: 10px;

  text-align: right;
}
#navbarSupportedContent > ul > li.nav-item.dropdown > ul > li:nth-child(1) > a {
  border-bottom: 1px solid white;
}
.dropdown-menu {
  padding: 0px;
  border-radius: 5px;
}
#navbarSupportedContent > ul > li.nav-item.dropdown > ul {
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
  background: black;
}
/*navbar end*/
.tierdrop {
  border: 2px solid white;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: white;
  padding: 15px 30px 15px 30px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  background: transparent;
  text-align: right;
}
.tt > div > div:nth-child(2) {
  text-align: right;
}
.btn:focus {
  box-shadow: 0 0 0 0rem;
}

div.container-fluid.dic > div > div.col-md-6 > h4 {
  font-weight: lighter;
}
hr {
  border: none;
  height: 3px;
  color: white;
  background-color: white;
  opacity: 1;
  width: 90%;
}
div.container-fluid.dic > div > div.col-md-6 > textarea {
  background-color: #1f1f21;
  border: 0px;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
}
div.container-fluid.dic > div > div.col-md-6 > textarea:focus-visible {
  border: 0px;
}
div.container-fluid.dic > div > div.col-md-6 > div > div > p {
  font-weight: lighter;
}
div.container-fluid.dic
  > div
  > div.col-md-6
  > div:nth-child(4)
  > div.col-md-6
  > p {
  text-align: right;
}
hr.line {
  border: none;
  height: 3px;
  color: white;
  background-color: white;
  opacity: 1;
  width: 50%;
  margin: auto;
}
div.container-fluid.dic > div > div.col-md-3.text-center > h2 {
  font-weight: bold;
  text-transform: uppercase;
}
.dicimg {
  margin-top: 150px;
  margin-bottom: 150px;
}
a.abtn.rol {
  padding: 10px 150px 10px 150px;
  font-size: 28px;
}

.row {
  --bs-gutter-x: 0rem;
}
h1 {
  font-weight: bold;
}

footer > div > div > div > h1 > s {
  font-size: 50px;
}
footer > div > div > div > h1 > span {
  color: #3fd999;
  font-size: 50px;
}
div.fbot {
  margin-top: 150px;
  margin-bottom: 100px;
}
div.fbot2 {
  margin-top: 0px;
  margin-bottom: 100px;
}

.formcontainer {
  text-align: center;
  margin: 24px 50px 12px;
}
.container {
  padding: 16px 0;
  text-align: left;
}
span.psw {
  float: right;
  padding-top: 0;
  padding-right: 15px;
}

input:focus {
  outline: none;
}

input.formsubmit {
  border: 3px solid #3fd999;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  color: #3fd999;
  padding: 10px 35px 10px 35px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  background: transparent;
  transition: 0.5s;
}
input.formsubmit:hover {
  background: #000;
  color: #fff;
  transition: 0.5s;
}
.formcontainer > div > label > strong {
  font-weight: normal;
}

.col-md-8 {
  display: flex;
  justify-content: space-between;
}

div.row.roundlog {
  border: 3px solid #2a2a2c;
  border-radius: 10px;
  background: #2a2a2c;
  /* visibility: hidden; */
  padding: 0px;
  height: 0px;
  width: 100%;
  /* height: 100%; */
}
.testi-p {
  display: flex;
  flex-direction: column;
}

.tier {
  list-style: none;
  padding-top: 80px;
  padding-bottom: 30px;
}
.tier-item {
  border: 1px solid white;
  width: 85%;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}
.tier-add {
  padding-bottom: 150px;
  text-align: center;
}
.spot {
  /* background:url('../images/spot.png'); */
  background-size: cover;
  padding-top: 50px;
  width: 20%;
}
a.tierdrop::after {
  /* background-image:url('../images/arrow.png'); */
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
}
.inner-tier {
  /* background:url('../images/tabletier.png'); */
  background-size: cover;
  padding-top: 50px;
}
.inner-tier > .tier {
  padding-right: 40px;
}
.tt {
  margin-left: 100px;
  margin-right: 100px;
}
#product > p {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 300;
}
#product > p > m {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 500;
}

#product {
  margin-top: 100px;
}
div.containers.bset {
  background-color: #1e1e20;
  border-radius: 30px;
}

.sup {
  padding: 50px 50px 15px 50px;
  border: 2px solid #328865;
  background: #272929;
}

.sup > p {
  margin-bottom: 0px;
}

div.sc-bdvvtL.hQjeFw {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

div.rrr {
  margin-right: -100px;
  margin-left: 50px;
  z-index: 8;
}

.fulhr {
  width: 100%;
}

/* Mobile Responsive */

/* Change styles for span on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
}

.testi-p {
  padding: 30px;
}

.testi-p {
  line-height: 2em;
}

.testi-i > img {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: auto;
}

.slick-dots li button:before {
  font-size: 20px;
  color: white;
}

.slick-dots li.slick-active button:before {
  font-size: 20px;
  color: #3fd999;
}
a.minii {
  font-size: 15px;
  color: #3fd999;
}
.linn::after {
  border-bottom: 2px solid #3fd999;
  width: 100%;
  content: "________";
  color: transparent;
}
.minii {
  font-size: 15px;
}
.tablinks {
  background-color: transparent;
  outline: 0px;
  border: 0px;
}
.s {
  color: #3fd999;
}
.grea {
  color: #3fd999;
  background-color: #3fd999;
}
.hQjeFw {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
}
.hQjeFw p {
  margin-left: 0%;
}
.fPWfao {
  margin-left: 0%;
  margin-top: 0%;
  width: 100%;
}
