.blog-container {
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}
/* Blog detail pages */
.blog-container-2 {
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  border: none !important;
  box-shadow: none !important;
  text-align: left !important;
  font-size: 2.5rem !important;
}

.PostContainer {
  width: 30%;
  margin: 10px;
  box-shadow: 1px 1px 5px black;
  /* height: 1000px; */
}

.blog-header {
  font-size: 40px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  margin-bottom: 5rem;
  text-align: center;
  margin-top: 50px;
}

.ImageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
}
.Image {
  height: 300px;
  width: 300px;
  width: 100%;
}
.blog-post {
  /* box-shadow: 1px 1px 5px black; */
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 630px;
}
.blog-link:hover {
  text-decoration: none !important;
}

.TDContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;

  width: 100%;
  padding: 1%;
  text-align: center;
}
.blog-h {
  font-size: 2.5rem;
  color: #04aa6d;
  padding-left: 5px;
  padding-right: 5px;
}
.blog-p {
  font-size: 2rem;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;

  /* text-align: left !important; */
}

.inner-PostContainer {
  width: 90%;
  font-size: 2.5rem !important;
}

@media only screen and (max-width: 600px) {
  .blog-header {
    font-size: 30px;
  }
  .Image {
    width: 100%;
  }
  .blog-post {
    width: 100%;
    margin: 0;
    min-height: auto;
  }

  .PostContainer,
  .inner-PostContainer {
    width: 100%;
    margin: 10px;
  }
}

h1.blog-header {
  width: 100%;
}

.PostContainer .blog-post .ImageContainer img {
  object-fit: cover;
}

.PostContainer .blog-post .blog-p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* number of lines to show */
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.pagination-loader {
  width: 65px;
}

.inner-PostContainer .blog-post .ImageContainer img {
  object-fit: cover;
  height: 100%;
}

.inner-PostContainer .blog-post iframe {
  width: 100%;
}

.pagination {
  justify-content: center;
  flex-wrap: wrap;
}

.pagination span:hover,
.pagination span.active {
  color: #04aa6d !important;
}

.blog-p img {
  height: auto !important;
}

.blog-p video {
  width: 100%;
}

.blog-p h2 {
  font-size: 35px;
}

.sidebar-container-vertical {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 3%;
}

.related-news-sidebar .sidebar-head {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

.sidebar-content {
  border: solid 1px #3fd999;
  height: fit-content;
  width: 100%;
  padding: 10px 10px 0 10px;
  border-radius: 5px;
}

.inner-PostContainer {
  display: flex;
}

.related-news-sidebar .post .post-image {
  max-width: 30%;
  border-radius: 5px;
  height: 55px;
  width: 70px;
  object-fit: cover;
}

.related-news-sidebar .post .post-head {
  width: 70%;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  margin-left: 8px;
}

.related-news-sidebar .post {
  display: flex;
  align-items: center;
  border-top: solid 1px #3fd999;
  padding: 10px 0;
}

@media only screen and (max-width: 900px) {
  .related-news-sidebar {
    display: none;
  }

  .related-news-sidebar.top-news {
    display: block;
  }

  .inner-PostContainer {
    flex-direction: column;
  }

  .sidebar-container-vertical {
    margin-left: 0;
    width: auto;
  }
}
